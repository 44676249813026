import { Tag } from '@chakra-ui/react'
import { useAuth } from 'context/AuthContext'
import { useCampaignInfo } from 'context/CampaignInfoContext'
import {
  FiCamera,
  FiHeadphones,
  FiHelpCircle,
  FiImage,
  FiInstagram,
  FiMonitor,
  FiUserCheck,
  FiVideo
} from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { loggedUserIsAdmin } from 'services/helpers'
import { Scrollbars } from 'react-custom-scrollbars'

import SidebarButton from './SidebarButton'

import * as S from './styles'

const Sidebar = () => {
  const { user } = useAuth()
  const { completedCampaignInfo } = useCampaignInfo()
  const isAdmin = loggedUserIsAdmin()

  return (
    <Scrollbars autoHide>
      <S.Wrapper>
        {!isAdmin && (
          <Link to={`/users/${user.id}/campaign`}>
            <SidebarButton
              title="Dados de campanha"
              icon={<FiUserCheck />}
              iconColor="#1ABC9C"
              tag={
                !completedCampaignInfo ? (
                  <Tag
                    size="sm"
                    borderRadius="2"
                    variant="solid"
                    colorScheme="yellow"
                  >
                    Completar dados
                  </Tag>
                ) : (
                  <Tag
                    size="sm"
                    borderRadius="2"
                    variant="solid"
                    colorScheme="green"
                  >
                    Dados completos
                  </Tag>
                )
              }
            >
              Adicione seus dados de campanha
            </SidebarButton>
          </Link>
        )}

        {isAdmin && (
          <Link to="/users">
            <SidebarButton
              title="Usuários"
              icon={<FiUserCheck />}
              iconColor="#1ABC9C"
            >
              Gerencie os usuários do sistema
            </SidebarButton>
          </Link>
        )}

        <Link to={isAdmin ? '/digital' : `/digital/user/${user.id}`}>
          <SidebarButton
            title="Materiais digitais"
            icon={<FiInstagram />}
            iconColor="#E74C3C"
          >
            {isAdmin
              ? 'Envie e gerencie os materiais gráficos dos usuários'
              : 'Baixe os materiais digitais produzidos para sua campanha'}
          </SidebarButton>
        </Link>

        <Link to={isAdmin ? '/videos' : `/videos/user/${user.id}`}>
          <SidebarButton title="Vídeos" icon={<FiVideo />} iconColor="#9B59B6">
            {isAdmin
              ? 'Envie e gerencie os vídeos dos usuários do sistema'
              : 'Baixe os vídeos produzidos para sua campanha'}
          </SidebarButton>
        </Link>

        <Link to={isAdmin ? '/audios' : `/audios/user/${user.id}`}>
          <SidebarButton
            title="Áudios"
            icon={<FiHeadphones />}
            iconColor="#1abc9c"
          >
            {isAdmin
              ? 'Envie e gerencie os áudios dos usuários do sistema'
              : 'Baixe os áudios produzidos para sua campanha'}
          </SidebarButton>
        </Link>

        <Link
          to={
            isAdmin
              ? '/graphics-material'
              : `/graphics-material/user/${user.id}`
          }
        >
          <SidebarButton
            title="Materiais gráficos"
            icon={<FiImage />}
            iconColor="#3498DB"
          >
            {isAdmin
              ? 'Envie e gerencie os materiais gráficos dos usuários'
              : 'Baixe os materiais gráficos produzidos para sua campanha'}
          </SidebarButton>
        </Link>

        <Link to={isAdmin ? '/photos' : `/photos/user/${user.id}`}>
          <SidebarButton title="Fotos" icon={<FiCamera />} iconColor="#f39c12">
            {isAdmin
              ? 'Envie e gerencie as fotos dos usuários'
              : 'Baixe as fotos produzidas para sua campanha'}
          </SidebarButton>
        </Link>

        <Link to={isAdmin ? '/websites' : `/websites/${user.site?.id}`}>
          <SidebarButton
            title={isAdmin ? 'Websites' : 'Website'}
            icon={<FiMonitor />}
            iconColor="#9b59b6"
          >
            {isAdmin
              ? 'Gerencie os websites dos usuários'
              : 'Gerencie seu website de campanha'}
          </SidebarButton>
        </Link>

        {!isAdmin && (
          <Link to="/">
            <SidebarButton
              title="Dúvidas"
              icon={<FiHelpCircle />}
              iconColor="#34495E"
            >
              Precisa de ajuda? Estamos prontos para ajudar você!
            </SidebarButton>
          </Link>
        )}
      </S.Wrapper>
    </Scrollbars>
  )
}

export default Sidebar
