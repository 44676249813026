import { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import Button from 'components/Button'
import api, { apiURL } from 'services/api'
import { FiEdit, FiTrash, FiVideo } from 'react-icons/fi'
import {
  Grid,
  GridItem,
  useToast,
  Tooltip,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel
} from '@chakra-ui/react'
import { ThemeProps, UserProps, VideoProps } from 'App.d'
import CardVideo from 'components/CardVideo'
import { loggedUserIsAdmin } from 'services/helpers'

const UserVideos = () => {
  const { id } = useParams()
  const toast = useToast()
  const isAdmin = loggedUserIsAdmin()
  const [user, setUser] = useState<UserProps>({} as UserProps)
  const [videos, setVideos] = useState<VideoProps[]>([])
  const [sharedVideos, setSharedVideos] = useState<VideoProps[]>([])
  const [themes, setThemes] = useState<ThemeProps[]>([])

  const handleDelete = useCallback(
    async (videoId: string) => {
      try {
        if (confirm('Tem certeza que deseja excluir?')) {
          const response = await api.delete(`/videos/${videoId}`)

          if (response.status === 200) {
            toast({
              description: 'O vídeo foi excluído com sucesso',
              status: 'success'
            })

            setVideos(videos.filter((video) => video.id !== videoId))
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    [toast, videos]
  )

  const setDownloaded = (videoId: string | undefined) => {
    const video = videos.filter((item) => item.id === videoId)[0]
    const videoDownaloded = { ...video, downloaded: true }
    const allVideos = videos.map((item) => {
      return item.id === videoId ? videoDownaloded : item
    })
    setVideos(allVideos)
  }

  useEffect(() => {
    api.get(`/users/${id}`).then((response) => {
      const userData = response.data
      setUser(userData)

      api
        .get(`/videos/shared/?partyId=${userData.campaign.partyId}`)
        .then((response) => {
          setSharedVideos(response.data)
        })
    })
  }, [id])

  useEffect(() => {
    api.get(`/videos/?userId=${id}`).then((response) => {
      setVideos(response.data)
    })
  }, [id])

  useEffect(() => {
    api.get('/themes').then((response) => {
      setThemes(response.data)
    })
  }, [])

  return (
    <Layout>
      {isAdmin && (
        <Link to={`/videos/upload/${id}`}>
          <Button
            size="small"
            style={{ marginBottom: '20px', marginLeft: 'auto' }}
          >
            Adicionar vídeo
          </Button>
        </Link>
      )}

      <Panel
        title={
          isAdmin
            ? `Vídeos do usuário ${user.fullName}`
            : 'Vídeos personalizados'
        }
        icon={<FiVideo color="#9B59B6" />}
        style={{ marginBottom: '20px' }}
        collapsible
        defaultClosed
      >
        <Grid templateColumns="repeat(12, 1fr)" gap={6}>
          {videos.map((video) => (
            <GridItem
              key={video.id}
              colSpan={{ base: 12, sm: 6, md: 6, lg: 3 }}
            >
              <CardVideo
                videoUrl={video.videoPath}
                title={video.title}
                downloaded={video.downloaded}
                footer={
                  isAdmin ? (
                    <div style={{ display: 'flex' }}>
                      <Tooltip label="Editar" hasArrow placement="top">
                        <Link
                          to={`/videos/${video.id}`}
                          style={{ marginRight: '8px' }}
                        >
                          <FiEdit size={25} color="#3498DB" />
                        </Link>
                      </Tooltip>

                      <Tooltip label="Deletar" hasArrow placement="top">
                        <Link to="#">
                          <FiTrash
                            size={25}
                            color="#E74C3C"
                            onClick={() => {
                              if (video.id) {
                                handleDelete(video.id)
                              }
                            }}
                          />
                        </Link>
                      </Tooltip>
                    </div>
                  ) : (
                    <a href={`${apiURL}/downloads/?type=videos&id=${video.id}`}>
                      <Button
                        size="small"
                        block
                        onClick={() => setDownloaded(video.id)}
                      >
                        Download
                      </Button>
                    </a>
                  )
                }
              />
            </GridItem>
          ))}
        </Grid>
      </Panel>

      {!isAdmin && (
        <Panel
          title="Vídeos temáticos"
          icon={<FiVideo color="#9B59B6" />}
          collapsible
          defaultClosed
        >
          <Tabs variant="soft-rounded" colorScheme="green">
            <TabList>
              {themes.map((theme) => (
                <Tab key={theme.id} fontSize="sm">
                  {theme.title}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {themes.map((theme) => (
                <TabPanel key={theme.id}>
                  <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                    {sharedVideos
                      .filter((video) => video.themeId === theme.id)
                      .map((video) => (
                        <GridItem
                          key={video.id}
                          colSpan={{ base: 12, sm: 6, md: 6, lg: 3 }}
                        >
                          <CardVideo
                            videoUrl={video.videoPath}
                            title={video.title}
                            footer={
                              isAdmin ? (
                                <div style={{ display: 'flex' }}>
                                  <Tooltip
                                    label="Editar"
                                    hasArrow
                                    placement="top"
                                  >
                                    <Link
                                      to={`/videos/${video.id}`}
                                      style={{ marginRight: '8px' }}
                                    >
                                      <FiEdit size={25} color="#3498DB" />
                                    </Link>
                                  </Tooltip>

                                  <Tooltip
                                    label="Deletar"
                                    hasArrow
                                    placement="top"
                                  >
                                    <Link to="#">
                                      <FiTrash
                                        size={25}
                                        color="#E74C3C"
                                        onClick={() => {
                                          if (video.id) {
                                            handleDelete(video.id)
                                          }
                                        }}
                                      />
                                    </Link>
                                  </Tooltip>
                                </div>
                              ) : (
                                <a
                                  href={`${apiURL}/downloads/?type=videos&id=${video.id}`}
                                >
                                  <Button size="small" block>
                                    Download
                                  </Button>
                                </a>
                              )
                            }
                          />
                        </GridItem>
                      ))}
                  </Grid>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Panel>
      )}
    </Layout>
  )
}

export default UserVideos
