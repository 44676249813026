import styled, { css } from 'styled-components'
import { shade } from 'polished'

import { ButtonProps } from '.'

export const Wrapper = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  font-size: 0.9375rem;
  height: 46px;
  font-weight: 600;
  color: #fff;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }

  ${({ theme, block, size, variant, defaultColor }) => css`
    ${!!block &&
    css`
      width: 100%;
    `}

    ${size == 'small' &&
    css`
      font-size: 0.875rem;
      height: 38px;
    `}

    ${size == 'large' &&
    css`
      font-size: 1.125rem;
      height: 54px;
    `}

    ${variant == 'warning' &&
    css`
      background: ${theme.colors.warning};
      border-bottom: 3px solid ${shade(0.1, theme.colors.warning)};
      text-shadow: 0 1px 1px ${shade(0.1, theme.colors.warning)};
      &:hover {
        background: ${shade(0.1, theme.colors.warning)};
      }
    `}

    ${variant == 'success' &&
    css`
      background: ${theme.colors.success};
      border-bottom: 3px solid ${shade(0.1, theme.colors.success)};
      text-shadow: 0 1px 1px ${shade(0.1, theme.colors.success)};
      &:hover {
        background: ${shade(0.1, theme.colors.success)};
      }
    `}

    border-bottom: 3px solid ${shade(
      0.1,
      defaultColor || theme.colors.primary
    )};
    text-shadow: 0 1px 1px ${shade(0.1, defaultColor || theme.colors.primary)};
    background: ${defaultColor || theme.colors.primary};

    &:hover {
      background: ${shade(0.1, defaultColor || theme.colors.primary)};
    }
  `}
`
