import * as Yup from 'yup'
import ValidationErrorsMessages from 'utils/validationErrorsMessages'

const formValidation = Yup.object().shape({
  type: Yup.string().required(ValidationErrorsMessages.required),
  fullName: Yup.string().required(ValidationErrorsMessages.required),
  email: Yup.string()
    .required(ValidationErrorsMessages.required)
    .email(ValidationErrorsMessages.email)
})

export default formValidation
