import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import { FiUserCheck } from 'react-icons/fi'
import { Grid, GridItem, useToast } from '@chakra-ui/react'
import { OfficeProps, PartyProps, UserProps } from 'App.d'
import { loggedUserIsAdmin } from 'services/helpers'
import api from 'services/api'

import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import Button from 'components/Button'
import Input from 'components/Input'
import Select from 'components/Select'
import BackButton from 'components/BackButton'
import ibgeApi from 'services/ibge'
import formValidation from './formValidation'

interface StateProps {
  id: number
  nome: string
  sigla: string
}

type FormProps = Omit<UserProps, 'id'>

const AddUser = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const isAdmin = loggedUserIsAdmin()
  const [loading, setLoading] = useState(false)
  const [userType, setUserType] = useState('')
  const [parties, setParties] = useState<PartyProps[]>([])
  const [states, setStates] = useState<StateProps[]>([])
  const [offices, setOffices] = useState<OfficeProps[]>([])

  const handleSubmit = useCallback(
    async (values: FormProps) => {
      try {
        setLoading(true)

        if (values.type === 'user' && !values.campaign?.partyId) {
          toast({
            description:
              'Para cadastrar um candidato(a) é necessário informar o partido',
            status: 'error'
          })
          setLoading(false)
          return false
        }

        const response = await api.post('/users', values)
        const user: UserProps = response.data

        if (values.campaign) await api.put(`/users/${user.id}`, values)

        if (user.id) {
          const form = new FormData()
          form.append('userId', user.id)

          await api.post('/sites', form)
        }

        toast({
          description: 'Usuário cadastrado com sucesso',
          status: 'success'
        })

        navigate('/users')
        setLoading(false)
      } catch (error) {
        toast({
          description: 'Ocorreu um erro ao cadastrar o usuário',
          status: 'error'
        })
        setLoading(false)
      }
    },
    [navigate, toast]
  )

  useEffect(() => {
    ibgeApi.get('/estados').then((response) => {
      setStates(response.data)
    })
  }, [])

  useEffect(() => {
    api.get('/parties').then((response) => {
      setParties(response.data)
    })
  }, [])

  useEffect(() => {
    api.get('/offices').then((response) => {
      setOffices(response.data)
    })
  }, [])

  return (
    <Layout containerMaxWidth="700">
      <a onClick={() => navigate(-1)}>
        <BackButton label="Voltar" style={{ marginBottom: '20px' }} />
      </a>

      <Panel
        title="Adicionar um novo usuário"
        icon={<FiUserCheck color="1ABC9C" />}
      >
        <Formik
          initialValues={
            {
              type: '',
              fullName: '',
              email: '',
              password: ''
            } as FormProps
          }
          onSubmit={handleSubmit}
          validationSchema={formValidation}
          enableReinitialize
        >
          {({
            values,
            handleBlur,
            handleChange,
            setFieldValue,
            errors,
            touched
          }) => (
            <Form>
              {isAdmin && (
                <Select
                  name="type"
                  defaultValue={values.type}
                  placeholder="Nível de acesso"
                  onBlur={handleBlur}
                  onChange={(event) => {
                    const targetValue = event.currentTarget.value
                    setFieldValue('type', targetValue)
                    setUserType(targetValue)
                  }}
                  style={{ marginBottom: '20px' }}
                  validationError={
                    errors.type && touched.type ? errors.type : null
                  }
                >
                  <option value="">Escolha</option>
                  <option value="admin">Administrador</option>
                  <option value="user">Candidato(a)</option>
                </Select>
              )}

              <Input
                name="fullName"
                placeholder="Nome"
                value={values.fullName}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{ marginBottom: '20px' }}
                validationError={
                  errors.fullName && touched.fullName ? errors.fullName : null
                }
              />

              <Input
                name="email"
                placeholder="E-mail"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{ marginBottom: '20px' }}
                validationError={
                  errors.email && touched.email ? errors.email : null
                }
              />

              {userType === 'user' && (
                <Input
                  name="phone"
                  placeholder="Telefone celular"
                  mask="(99) 99999-9999"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                />
              )}

              <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                <GridItem>
                  <Input
                    type="password"
                    name="password"
                    placeholder="Senha"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                    validationError={
                      errors.password && touched.password
                        ? errors.password
                        : null
                    }
                  />
                </GridItem>

                <GridItem>
                  <Input
                    type="password"
                    name="password_conf"
                    placeholder="Confirme a senha"
                    value={''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                  />
                </GridItem>
              </Grid>

              {userType === 'user' && (
                <>
                  <h5
                    style={{
                      marginBottom: '25px',
                      marginTop: '10px',
                      fontWeight: '600'
                    }}
                  >
                    Dados eleitorais
                  </h5>

                  <Select
                    name="campaign.partyId"
                    defaultValue={values.campaign?.partyId}
                    placeholder="Partido"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                  >
                    <option value="">Escolha o partido</option>
                    {parties.map((party) => (
                      <option key={party.id} value={party.id}>
                        {`${party.name} (${party.initials})`}
                      </option>
                    ))}
                  </Select>

                  <Select
                    name="campaign.officeId"
                    defaultValue={
                      values.campaign ? values.campaign.officeId : ''
                    }
                    placeholder="Cargo em que irá concorrer"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                  >
                    <option value="">Escolha o cargo</option>
                    {offices.map((office) => (
                      <option key={office.id} value={office.id}>
                        {office.title}
                      </option>
                    ))}
                  </Select>

                  <Input
                    name="campaign.electoralName"
                    placeholder="Nome de urna"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                  />

                  <Input
                    name="campaign.electoralNumber"
                    placeholder="Número de candidato"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                  />

                  <Input
                    name="campaign.cnpj"
                    mask="99.999.999/9999-99"
                    placeholder="CNPJ da campanha"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                  />

                  <Select
                    name="campaign.state"
                    defaultValue={values.campaign ? values.campaign.state : ''}
                    placeholder="Estado em que irá concorrer"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                  >
                    <option value="">Escolha o estado</option>
                    {states.map((state) => (
                      <option key={state.id} value={state.sigla}>
                        {state.nome}
                      </option>
                    ))}
                  </Select>

                  <h5
                    style={{
                      marginBottom: '25px',
                      marginTop: '30px',
                      fontWeight: '600'
                    }}
                  >
                    Dados do assessor(a)
                  </h5>

                  <Input
                    name="campaign.accessorName"
                    placeholder="Nome do assessor(a)"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                  />

                  <Input
                    name="campaign.accessorEmail"
                    placeholder="E-mail do assessor(a)"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                  />

                  <Input
                    name="campaign.accessorPhone"
                    placeholder="Telefone celular do assessor(a)"
                    mask="(99) 99999-9999"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                  />
                </>
              )}

              <Button type="submit" loading={loading}>
                Salvar informações
              </Button>
            </Form>
          )}
        </Formik>
      </Panel>
    </Layout>
  )
}

export default AddUser
