import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import { FiInstagram, FiTrash } from 'react-icons/fi'
import { Link, useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { UserProps, DigitalProps, ThemeProps } from 'App.d'
import api, { apiURL } from 'services/api'
import Button from 'components/Button'
import { loggedUserIsAdmin } from 'services/helpers'
import {
  Grid,
  GridItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
  useToast
} from '@chakra-ui/react'
import Card from 'components/Card'

const UserDigital = () => {
  const { id } = useParams()
  const toast = useToast()
  const isAdmin = loggedUserIsAdmin()
  const [user, setUser] = useState<UserProps>({} as UserProps)
  const [materials, setMaterials] = useState<DigitalProps[]>([])
  const [cards, setCards] = useState<DigitalProps[]>([])
  const [sharedMaterials, setSharedMaterials] = useState<DigitalProps[]>([])
  const [themes, setThemes] = useState<ThemeProps[]>([])

  const handleDelete = useCallback(
    async (materialId: string | undefined) => {
      try {
        if (confirm('Tem certeza que deseja deletar?')) {
          const response = await api.delete(`/material-digitals/${materialId}`)

          if (response.status === 200) {
            toast({
              description: 'Material deletado com sucesso',
              status: 'success'
            })

            setMaterials(
              materials.filter((material) => material.id !== materialId)
            )

            setSharedMaterials(
              sharedMaterials.filter(
                (sharedMaterial) => sharedMaterial.id !== materialId
              )
            )
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    [toast, materials, sharedMaterials]
  )

  const setDownloaded = (materialId: string | undefined) => {
    const material = materials.filter((item) => item.id === materialId)[0]
    const materialDownaloded = { ...material, downloaded: true }
    const allMaterials = materials.map((item) => {
      return item.id === materialId ? materialDownaloded : item
    })
    setMaterials(allMaterials)
  }

  useEffect(() => {
    api.get('/themes').then((response) => {
      setThemes(response.data)
    })
  }, [])

  useEffect(() => {
    api.get(`/material-digitals/?userId=${id}`).then((response) => {
      setMaterials(
        response.data.filter(
          (item: DigitalProps) => item.title !== 'Card para redes sociais'
        )
      )

      setCards(
        response.data.filter(
          (item: DigitalProps) => item.title === 'Card para redes sociais'
        )
      )
    })
  }, [id])

  useEffect(() => {
    api.get(`/users/${id}`).then((response) => {
      const userData = response.data
      setUser(userData)

      api
        .get(
          `/material-digitals/shared/?partyId=${userData.campaign.partyId}&category=${userData.campaign.officeId}`
        )
        .then((response) => {
          setSharedMaterials(
            response.data.filter(
              (material: DigitalProps) =>
                material.category === userData.campaign.officeId
            )
          )
        })
    })
  }, [id])

  return (
    <Layout>
      {isAdmin && (
        <Link to={`/digital/upload/${id}`}>
          <Button
            size="small"
            style={{ marginBottom: '20px', marginLeft: 'auto' }}
          >
            Adicionar material
          </Button>
        </Link>
      )}

      <Panel
        title={
          isAdmin
            ? `Materiais digitais do candidato(a) ${user.fullName}`
            : 'Materiais digitais personalizados'
        }
        icon={<FiInstagram color="#E74C3C" />}
        collapsible
        defaultClosed
        style={{ marginBottom: '20px' }}
      >
        <Tabs variant="soft-rounded" colorScheme="green">
          <TabList>
            <Tab fontSize="sm">Cards para redes sociais</Tab>
            <Tab fontSize="sm">Outros materiais digitais</Tab>
          </TabList>
          <TabPanels>
            <TabPanel paddingStart="0" paddingEnd="0">
              <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                {cards.map((card) => (
                  <GridItem
                    key={card.id}
                    colSpan={{ base: 12, sm: 6, md: 6, lg: 3 }}
                  >
                    <Card
                      title={card.title}
                      imgUrl={card.materialPath}
                      downloaded={card.downloaded}
                      footer={
                        isAdmin ? (
                          <div style={{ display: 'flex' }}>
                            <Tooltip label="Deletar" hasArrow placement="top">
                              <Link to="#">
                                <FiTrash
                                  size={25}
                                  color="#E74C3C"
                                  onClick={() => handleDelete(card.id)}
                                />
                              </Link>
                            </Tooltip>
                          </div>
                        ) : (
                          <a
                            href={`${apiURL}/downloads/?type=material-digitals&id=${card.id}`}
                          >
                            <Button
                              block
                              onClick={() => {
                                setDownloaded(card.id)
                              }}
                            >
                              Download
                            </Button>
                          </a>
                        )
                      }
                    />
                  </GridItem>
                ))}
              </Grid>
            </TabPanel>
            <TabPanel>
              <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                {materials.map((material) => (
                  <GridItem
                    key={material.id}
                    colSpan={{ base: 12, sm: 6, md: 6, lg: 3 }}
                  >
                    <Card
                      title={material.title}
                      imgUrl={material.materialPath}
                      downloaded={material.downloaded}
                      footer={
                        isAdmin ? (
                          <div style={{ display: 'flex' }}>
                            <Tooltip label="Deletar" hasArrow placement="top">
                              <Link to="#">
                                <FiTrash
                                  size={25}
                                  color="#E74C3C"
                                  onClick={() => handleDelete(material.id)}
                                />
                              </Link>
                            </Tooltip>
                          </div>
                        ) : (
                          <a
                            href={`${apiURL}/downloads/?type=material-digitals&id=${material.id}`}
                          >
                            <Button
                              block
                              onClick={() => {
                                setDownloaded(material.id)
                              }}
                            >
                              Download
                            </Button>
                          </a>
                        )
                      }
                    />
                  </GridItem>
                ))}
              </Grid>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Panel>

      {!isAdmin && (
        <Panel
          title="Cards temáticos"
          icon={<FiInstagram color="#E74C3C" />}
          collapsible
          defaultClosed
        >
          <Tabs variant="soft-rounded" colorScheme="green">
            <TabList>
              {themes.map((theme) => (
                <Tab key={theme.id} fontSize="sm">
                  {theme.title}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {themes.map((theme) => (
                <TabPanel key={theme.id} paddingStart="0" paddingEnd="0">
                  <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                    {sharedMaterials
                      .filter((material) => material.themeId === theme.id)
                      .map((material) => (
                        <GridItem
                          key={material.id}
                          colSpan={{ base: 12, sm: 6, md: 6, lg: 3 }}
                        >
                          <Card
                            title={material.title}
                            imgUrl={material.materialPath}
                            footer={
                              isAdmin ? (
                                <div style={{ display: 'flex' }}>
                                  <Tooltip
                                    label="Deletar"
                                    hasArrow
                                    placement="top"
                                  >
                                    <Link to="#">
                                      <FiTrash
                                        size={25}
                                        color="#E74C3C"
                                        onClick={() =>
                                          handleDelete(material.id)
                                        }
                                      />
                                    </Link>
                                  </Tooltip>
                                </div>
                              ) : (
                                <a
                                  href={`${apiURL}/downloads/?type=material-digitals&id=${material.id}`}
                                >
                                  <Button block>Download</Button>
                                </a>
                              )
                            }
                          />
                        </GridItem>
                      ))}
                  </Grid>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Panel>
      )}
    </Layout>
  )
}

export default UserDigital
