import styled from 'styled-components'

export const Wrapper = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  color: var(--gray-800);
  font-weight: 600;
  font-size: 1rem;

  > svg {
    margin-right: 5px;
  }
`
