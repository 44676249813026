import { ButtonHTMLAttributes } from 'react'
import * as S from './styles'
import { FiArrowLeft } from 'react-icons/fi'

type BackButtonProps = {
  label: string
} & ButtonHTMLAttributes<HTMLButtonElement>

const BackButton = ({ label, ...rest }: BackButtonProps) => (
  <S.Wrapper {...rest}>
    <FiArrowLeft /> {label}
  </S.Wrapper>
)

export default BackButton
