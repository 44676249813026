import styled, { css } from 'styled-components'

interface DropContainerProps {
  dragAccept: boolean
  dragReject: boolean
}

export const DropContainer = styled.div<DropContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border: 2px dashed var(--gray-500);
  border-radius: 2px;
  cursor: pointer;
  .drop-container-icon {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
    color: var(--gray-600);
  }
  p {
    margin: 0;
    text-align: center;
    small {
      display: block;
      font-size: 0.875rem;
      color: var(--gray-600);
    }
  }
  ${(props) =>
    props.dragAccept &&
    css`
      border-color: #2ecc71;
      .drop-container-icon,
      p {
        color: #2ecc71;
      }
    `}
  ${(props) =>
    props.dragReject &&
    css`
      border-color: #e74c3c;
      .drop-container-icon,
      p {
        color: #e74c3c;
      }
    `}
`

export const Preview = styled.div`
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-gap: 10px;
  margin-top: 20px;
`
