import * as S from './styles'

import { FiImage, FiPlusCircle, FiVideo } from 'react-icons/fi'

export interface NotificationProps {
  date: string
  title: string
  type: string
}

const Notification = ({ date, title, type }: NotificationProps) => {
  const getNotificationIcon = (type: string) => {
    if (type === 'material-graphics') {
      return <FiImage color="#3498DB" />
    } else if (type === 'videos') {
      return <FiVideo color="#9B59B6" />
    }
  }

  return (
    <S.Wrapper>
      <S.Icon>{getNotificationIcon(type)}</S.Icon>
      <S.Content>
        <span>{date}</span>
        {title}
      </S.Content>
      <S.WrapperButton>
        <FiPlusCircle />
      </S.WrapperButton>
    </S.Wrapper>
  )
}

export default Notification
