import styled from 'styled-components'
import { breakpoints as sizes } from 'styles/breakpoints'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 86px;
  background: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.102956);
`

export const MobileMenuWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  display: none;
  width: 24px;
  height: 24px;

  @media (${sizes.md}) {
    display: block;
  }
`

export const UserWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  padding-right: 20px;
  height: 86px;
`

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const Logo = styled.img`
  height: 50px;
  width: auto;
`
