import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import { loggedUserIsAdmin } from 'services/helpers'
import { Link, useParams } from 'react-router-dom'
import Button from 'components/Button'
import { useCallback, useEffect, useState } from 'react'
import { UserProps, PhotoProps } from 'App.d'
import api, { apiURL } from 'services/api'
import { FiCamera, FiTrash } from 'react-icons/fi'
import { Grid, GridItem, Tooltip, useToast } from '@chakra-ui/react'
import Card from 'components/Card'

const UserPhotos = () => {
  const { id } = useParams()
  const toast = useToast()
  const isAdmin = loggedUserIsAdmin()
  const [user, setUser] = useState<UserProps>({} as UserProps)
  const [photos, setPhotos] = useState<PhotoProps[]>([])

  const handleDelete = useCallback(
    async (photoId: string | undefined) => {
      try {
        if (confirm('Tem certeza que deseja deletar?')) {
          const response = await api.delete(`/photos/${photoId}`)

          if (response.status === 200) {
            toast({
              description: 'Foto deletada com sucesso',
              status: 'success'
            })

            setPhotos(photos.filter((photo) => photo.id !== photoId))
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    [toast, photos]
  )

  const setDownloaded = (photoId: string | undefined) => {
    const photo = photos.filter((item) => item.id === photoId)[0]
    const photoDownaloded = { ...photo, downloaded: true }
    const allPhotos = photos.map((item) => {
      return item.id === photoId ? photoDownaloded : item
    })
    setPhotos(allPhotos)
  }

  useEffect(() => {
    api.get(`/users/${id}`).then((response) => {
      setUser(response.data)
    })
  }, [id])

  useEffect(() => {
    api.get(`/photos/?userId=${id}`).then((response) => {
      setPhotos(response.data)
    })
  }, [id])

  return (
    <Layout>
      {isAdmin && (
        <Link to={`/photos/upload/${id}`}>
          <Button
            size="small"
            style={{ marginBottom: '20px', marginLeft: 'auto' }}
          >
            Adicionar fotos
          </Button>
        </Link>
      )}

      <Panel
        title={
          isAdmin
            ? `Fotos do usuário ${user.fullName}`
            : 'Fotos disponíveis para download'
        }
        icon={<FiCamera color="#f39c12" />}
      >
        <Grid templateColumns="repeat(12, 1fr)" gap={6}>
          {photos.map((photo) => (
            <GridItem
              key={photo.id}
              colSpan={{ base: 12, sm: 6, md: 6, lg: 3 }}
            >
              <Card
                title="Foto de campanha"
                imgUrl={photo.photoPath}
                downloaded={photo.downloaded}
                footer={
                  isAdmin ? (
                    <div style={{ display: 'flex' }}>
                      <Tooltip label="Deletar" hasArrow placement="top">
                        <Link to="#">
                          <FiTrash
                            size={25}
                            color="#E74C3C"
                            onClick={() => handleDelete(photo.id)}
                          />
                        </Link>
                      </Tooltip>
                    </div>
                  ) : (
                    <a href={`${apiURL}/downloads/?type=photos&id=${photo.id}`}>
                      <Button block onClick={() => setDownloaded(photo.id)}>
                        Download
                      </Button>
                    </a>
                  )
                }
              />
            </GridItem>
          ))}
        </Grid>
      </Panel>
    </Layout>
  )
}

export default UserPhotos
