import { ButtonHTMLAttributes } from 'react'
import { Circles } from 'react-loader-spinner'

import * as S from './styles'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  block?: boolean
  size?: 'small' | 'medium' | 'large'
  variant?: 'primary' | 'warning' | 'success'
  loading?: boolean
  disabled?: boolean
  defaultColor?: string
  children: React.ReactNode
}

const Button = ({
  block = false,
  size = 'medium',
  variant = 'primary',
  loading = false,
  disabled = false,
  defaultColor,
  children,
  ...rest
}: ButtonProps) => (
  <S.Wrapper
    block={block}
    size={size}
    variant={variant}
    disabled={loading || disabled}
    defaultColor={defaultColor}
    {...rest}
  >
    {loading ? <Circles color="#fff" height={20} width={20} /> : children}
  </S.Wrapper>
)

export default Button
