import React, { createContext, useCallback, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import defaultTheme from 'styles/themes/default'

interface MyThemeProviderProps {
  children: React.ReactNode
}

export const ThemeUpdateContext = createContext((color: string) =>
  console.log(
    'attempted to set theme outside of a ThemeUpdateContext.Provider',
    color
  )
)

export const MyThemeProvider = ({ children }: MyThemeProviderProps) => {
  const [myTheme, setMyTheme] = useState(defaultTheme)

  const updateTheme = useCallback(
    (color: string) => {
      setMyTheme({
        ...myTheme,
        colors: {
          ...myTheme.colors,
          primary: color
        }
      })
    },
    [myTheme, setMyTheme]
  )

  return (
    <ThemeProvider theme={myTheme}>
      <ThemeUpdateContext.Provider value={updateTheme}>
        {children}
      </ThemeUpdateContext.Provider>
    </ThemeProvider>
  )
}
