import { Routes, Route } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'

import Dashboard from 'pages/Dashboard'
import Login from 'pages/Login'
import Videos from 'pages/Videos'
import AddVideo from 'pages/Videos/AddVideo'
import UserVideos from 'pages/Videos/UserVideos'
import EditVideo from 'pages/Videos/EditVideo'
import Users from 'pages/Users'
import EditUser from 'pages/Users/EditUser'
import AddUser from 'pages/Users/AddUser'
import EditCampaign from 'pages/Users/EditCampaign'
import GraphicsMaterial from 'pages/GraphicsMaterial'
import UserGraphicsMaterial from 'pages/GraphicsMaterial/UserGraphicsMaterial'
import AddGraphicsMaterial from 'pages/GraphicsMaterial/AddGraphicsMaterial'
import EditGraphicsMaterial from 'pages/GraphicsMaterial/EditGraphicsMaterial'
import Photos from 'pages/Photos'
import UserPhotos from 'pages/Photos/UserPhotos'
import AddPhoto from 'pages/Photos/AddPhoto'
import Digital from 'pages/Digital'
import UserDigital from 'pages/Digital/UserDigital'
import AddDigital from 'pages/Digital/AddDigital'
import Audios from 'pages/Audios'
import UserAudios from 'pages/Audios/UserAudios'
import AddAudio from 'pages/Audios/AddAudio'
import EditAudio from 'pages/Audios/EditAudio'
import SharedVideos from 'pages/Videos/SharedVideos'
import SharedDigital from 'pages/Digital/SharedDigital'
import SharedAudios from 'pages/Audios/SharedAudios'
import Parties from 'pages/Parties'
import EditParty from 'pages/Parties/EditParty'
import AddParty from 'pages/Parties/AddParty'
import Themes from 'pages/Themes'
import AddTheme from 'pages/Themes/AddTheme'
import EditTheme from 'pages/Themes/EditTheme'
import Flags from 'pages/Flags'
import AddFlag from 'pages/Flags/AddFlag'
import EditFlag from 'pages/Flags/EditFlag'
import Websites from 'pages/Websites'
import EditWebsite from 'pages/Websites/EditWebsite'
import WebsitePreview from 'pages/Websites/WebsitePreview'

const Router = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/videos"
        element={
          <PrivateRoute>
            <Videos />
          </PrivateRoute>
        }
      />
      <Route
        path="/videos/upload"
        element={
          <PrivateRoute>
            <AddVideo />
          </PrivateRoute>
        }
      />
      <Route
        path="/videos/upload/:id"
        element={
          <PrivateRoute>
            <AddVideo />
          </PrivateRoute>
        }
      />
      <Route
        path="/videos/upload"
        element={
          <PrivateRoute>
            <AddVideo />
          </PrivateRoute>
        }
      />
      <Route
        path="/videos/:id"
        element={
          <PrivateRoute>
            <EditVideo />
          </PrivateRoute>
        }
      />
      <Route
        path="/videos/user/:id"
        element={
          <PrivateRoute>
            <UserVideos />
          </PrivateRoute>
        }
      />
      <Route
        path="/videos/shared"
        element={
          <PrivateRoute>
            <SharedVideos />
          </PrivateRoute>
        }
      />
      <Route
        path="/users"
        element={
          <PrivateRoute>
            <Users />
          </PrivateRoute>
        }
      />
      <Route
        path="/users/add"
        element={
          <PrivateRoute>
            <AddUser />
          </PrivateRoute>
        }
      />
      <Route
        path="/users/:id"
        element={
          <PrivateRoute>
            <EditUser />
          </PrivateRoute>
        }
      />
      <Route
        path="/users/:id/campaign"
        element={
          <PrivateRoute>
            <EditCampaign />
          </PrivateRoute>
        }
      />
      <Route
        path="/graphics-material"
        element={
          <PrivateRoute>
            <GraphicsMaterial />
          </PrivateRoute>
        }
      />
      <Route
        path="/graphics-material/upload/:id"
        element={
          <PrivateRoute>
            <AddGraphicsMaterial />
          </PrivateRoute>
        }
      />
      <Route
        path="/graphics-material/user/:id"
        element={
          <PrivateRoute>
            <UserGraphicsMaterial />
          </PrivateRoute>
        }
      />
      <Route
        path="/graphics-material/:id"
        element={
          <PrivateRoute>
            <EditGraphicsMaterial />
          </PrivateRoute>
        }
      />
      <Route
        path="/photos"
        element={
          <PrivateRoute>
            <Photos />
          </PrivateRoute>
        }
      />
      <Route
        path="/photos/user/:id"
        element={
          <PrivateRoute>
            <UserPhotos />
          </PrivateRoute>
        }
      />
      <Route
        path="/photos/upload/:id"
        element={
          <PrivateRoute>
            <AddPhoto />
          </PrivateRoute>
        }
      />
      <Route
        path="/digital"
        element={
          <PrivateRoute>
            <Digital />
          </PrivateRoute>
        }
      />
      <Route
        path="/digital/shared"
        element={
          <PrivateRoute>
            <SharedDigital />
          </PrivateRoute>
        }
      />
      <Route
        path="/digital/user/:id"
        element={
          <PrivateRoute>
            <UserDigital />
          </PrivateRoute>
        }
      />
      <Route
        path="/digital/upload/:id"
        element={
          <PrivateRoute>
            <AddDigital />
          </PrivateRoute>
        }
      />
      <Route
        path="/digital/upload"
        element={
          <PrivateRoute>
            <AddDigital />
          </PrivateRoute>
        }
      />
      <Route
        path="/audios"
        element={
          <PrivateRoute>
            <Audios />
          </PrivateRoute>
        }
      />
      <Route
        path="/audios/shared"
        element={
          <PrivateRoute>
            <SharedAudios />
          </PrivateRoute>
        }
      />
      <Route
        path="/audios/user/:id"
        element={
          <PrivateRoute>
            <UserAudios />
          </PrivateRoute>
        }
      />
      <Route
        path="/audios/upload/:id"
        element={
          <PrivateRoute>
            <AddAudio />
          </PrivateRoute>
        }
      />
      <Route
        path="/audios/upload"
        element={
          <PrivateRoute>
            <AddAudio />
          </PrivateRoute>
        }
      />
      <Route
        path="/audios/:id"
        element={
          <PrivateRoute>
            <EditAudio />
          </PrivateRoute>
        }
      />
      <Route
        path="/parties"
        element={
          <PrivateRoute>
            <Parties />
          </PrivateRoute>
        }
      />
      <Route
        path="/parties/:id"
        element={
          <PrivateRoute>
            <EditParty />
          </PrivateRoute>
        }
      />
      <Route
        path="/parties/add"
        element={
          <PrivateRoute>
            <AddParty />
          </PrivateRoute>
        }
      />
      <Route
        path="/themes"
        element={
          <PrivateRoute>
            <Themes />
          </PrivateRoute>
        }
      />
      <Route
        path="/themes/add"
        element={
          <PrivateRoute>
            <AddTheme />
          </PrivateRoute>
        }
      />
      <Route
        path="/themes/:id"
        element={
          <PrivateRoute>
            <EditTheme />
          </PrivateRoute>
        }
      />
      <Route
        path="/flags"
        element={
          <PrivateRoute>
            <Flags />
          </PrivateRoute>
        }
      />
      <Route
        path="/flags/add"
        element={
          <PrivateRoute>
            <AddFlag />
          </PrivateRoute>
        }
      />
      <Route
        path="/flags/:id"
        element={
          <PrivateRoute>
            <EditFlag />
          </PrivateRoute>
        }
      />
      <Route
        path="/websites"
        element={
          <PrivateRoute>
            <Websites />
          </PrivateRoute>
        }
      />
      <Route
        path="/websites/:id"
        element={
          <PrivateRoute>
            <EditWebsite />
          </PrivateRoute>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/login/:slug" element={<Login />} />
      <Route path="/:slug" element={<WebsitePreview />} />
    </Routes>
  )
}

export default Router
