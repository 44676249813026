import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import { FiEdit, FiTrash, FiVideo } from 'react-icons/fi'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { OfficeProps, PartyProps, ThemeProps, VideoProps } from 'App.d'
import api, { apiURL } from 'services/api'
import Button from 'components/Button'
import { loggedUserIsAdmin } from 'services/helpers'
import {
  Grid,
  GridItem,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
  useToast
} from '@chakra-ui/react'
import CardVideo from 'components/CardVideo'

const SharedVideos = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const isAdmin = loggedUserIsAdmin()
  const [searchParams] = useSearchParams()
  const [offices, setOffices] = useState<OfficeProps[]>([])
  const [videos, setVideos] = useState<VideoProps[]>([])
  const [selectedVideos, setSelectedVideos] = useState<VideoProps[]>([])
  const [parties, setParties] = useState<PartyProps[]>([])
  const [themes, setThemes] = useState<ThemeProps[]>([])
  const [partyId, setPartyId] = useState<string | null>(() => {
    return searchParams.get('partyId') ? searchParams.get('partyId') : null
  })

  const handleDelete = useCallback(
    async (videoId: string | undefined) => {
      try {
        if (confirm('Tem certeza que deseja deletar?')) {
          const response = await api.delete(`/videos/${videoId}`)

          if (response.status === 200) {
            toast({
              description: 'Vídeo deletado com sucesso',
              status: 'success'
            })

            setVideos(videos.filter((video) => video.id !== videoId))
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    [toast, videos]
  )

  const filterVideosByThemeAndOffice = (
    themeId: string | undefined,
    officeId: string | undefined
  ) => {
    return selectedVideos.filter(
      (video) => video.themeId === themeId && video.category === officeId
    )
  }

  useEffect(() => {
    api.get('/offices').then((response) => {
      setOffices(response.data)
      console.log('offices', response.data)
    })
  }, [])

  useEffect(() => {
    api.get('/themes').then((response) => {
      setThemes(response.data)
    })
  }, [])

  useEffect(() => {
    api.get('/parties').then((response) => {
      setParties(response.data)
    })
  }, [])

  useEffect(() => {
    api.get('/videos/shared').then((response) => {
      setVideos(response.data)
    })
  }, [])

  useEffect(() => {
    if (searchParams.get('partyId') !== '') {
      setSelectedVideos(
        videos.filter((video) => {
          if (video.partyId) {
            return video.partyId === searchParams.get('partyId')
          }
        })
      )

      setPartyId(searchParams.get('partyId'))
    }
  }, [videos, searchParams])

  return (
    <Layout>
      {isAdmin && (
        <Link to={`/videos/upload`}>
          <Button
            size="small"
            style={{ marginBottom: '20px', marginLeft: 'auto' }}
          >
            Adicionar vídeo
          </Button>
        </Link>
      )}

      <Panel title="Vídeos compartilhados" icon={<FiVideo color="#9B59B6" />}>
        <div style={{ marginBottom: '10px', width: '350px' }}>
          <Select
            placeholder="Escolha o partido"
            value={searchParams.get('partyId') || ''}
            size="sm"
            colorScheme="green"
            onChange={(event) => {
              const selectedPartyId = event.currentTarget.value

              selectedPartyId === ''
                ? navigate('/videos/shared')
                : navigate(`/videos/shared?partyId=${selectedPartyId}`)
            }}
          >
            {parties.map((party) => (
              <option key={party.id} value={party.id}>
                {party.name} ({party.initials})
              </option>
            ))}
          </Select>
        </div>

        {partyId && (
          <Tabs colorScheme="green">
            <TabList>
              {offices.map((office) => (
                <Tab key={office.id}>{office.title}</Tab>
              ))}
            </TabList>

            <TabPanels>
              {offices.map((office) => (
                <TabPanel key={office.id} paddingStart="0" paddingEnd="0">
                  <Tabs variant="soft-rounded" colorScheme="green">
                    <TabList>
                      {themes.map((theme) => (
                        <Tab key={theme.id} fontSize="sm">
                          {theme.title}
                        </Tab>
                      ))}
                    </TabList>
                    <TabPanels>
                      {themes.map((theme) => (
                        <TabPanel
                          key={theme.id}
                          paddingStart="0"
                          paddingEnd="0"
                        >
                          <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                            {filterVideosByThemeAndOffice(theme.id, office.id)
                              .length > 0 ? (
                              filterVideosByThemeAndOffice(
                                theme.id,
                                office.id
                              ).map((video) => (
                                <GridItem
                                  key={video.id}
                                  colSpan={{ base: 12, sm: 6, md: 6, lg: 3 }}
                                >
                                  <CardVideo
                                    title={video.title}
                                    videoUrl={video.videoPath}
                                    footer={
                                      isAdmin ? (
                                        <div style={{ display: 'flex' }}>
                                          <Tooltip
                                            label="Editar"
                                            hasArrow
                                            placement="top"
                                          >
                                            <Link
                                              to={`/videos/${video.id}`}
                                              style={{ marginRight: '8px' }}
                                            >
                                              <FiEdit
                                                size={25}
                                                color="#3498DB"
                                              />
                                            </Link>
                                          </Tooltip>

                                          <Tooltip
                                            label="Deletar"
                                            hasArrow
                                            placement="top"
                                          >
                                            <Link to="#">
                                              <FiTrash
                                                size={25}
                                                color="#E74C3C"
                                                onClick={() => {
                                                  if (video.id) {
                                                    handleDelete(video.id)
                                                  }
                                                }}
                                              />
                                            </Link>
                                          </Tooltip>
                                        </div>
                                      ) : (
                                        <a
                                          href={`${apiURL}/downloads/?type=videos&id=${video.id}`}
                                        >
                                          <Button block>Download</Button>
                                        </a>
                                      )
                                    }
                                  />
                                </GridItem>
                              ))
                            ) : (
                              <GridItem colSpan={12}>
                                <p>Nenhum vídeo encontrado</p>
                              </GridItem>
                            )}
                          </Grid>
                        </TabPanel>
                      ))}
                    </TabPanels>
                  </Tabs>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        )}
      </Panel>
    </Layout>
  )
}

export default SharedVideos
