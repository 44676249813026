import { NotificationProps, UserProps } from 'App.d'
import api from './api'

export const loggedUserIsAdmin = () => {
  const userLocalData = localStorage.getItem('@MinhaCandidatura:user')
  if (userLocalData) {
    const user = JSON.parse(userLocalData)

    if (user.type === 'admin') {
      return true
    }

    return false
  }
}

export const userCompletedCampaignInfo = async (userId: string) => {
  try {
    const response = await api.get(`/users/${userId}`)
    const user: UserProps = response.data

    if (
      user.campaign &&
      user.campaign.electoralName &&
      user.campaign.electoralNumber &&
      user.campaign.partyId &&
      user.campaign.officeId &&
      user.campaign.cnpj &&
      user.campaign.state
    ) {
      return true
    } else {
      return false
    }
  } catch (error) {
    console.log(error)
  }
}

export const getFirstNameByFullName = (fullName: string) => {
  return fullName.split(' ').slice(0, 1).join(' ')
}

export const getNotificationTitle = (notification: NotificationProps) => {
  const getNotificationType = (type: string) => {
    if (type === 'videos') {
      return 'Vídeos'
    } else if (type === 'material-graphics') {
      return 'Materiais gráficos'
    }
  }

  return `Um novo arquivo já está disponível na categoria ${getNotificationType(
    notification.type
  )}`
}
