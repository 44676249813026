import {
  Heading,
  SimpleGrid,
  Stat,
  StatHelpText,
  StatNumber
} from '@chakra-ui/react'
import {
  AudioProps,
  DigitalProps,
  GraphicsMaterialProps,
  PhotoProps,
  VideoProps
} from 'App.d'
import Panel from 'components/Panel'
import { useAuth } from 'context/AuthContext'
import { useEffect, useState } from 'react'
import {
  FiHeadphones,
  FiImage,
  FiInstagram,
  FiMonitor,
  FiVideo
} from 'react-icons/fi'
import api from 'services/api'

const Statistics = () => {
  const { user } = useAuth()

  const [digital, setDigital] = useState<DigitalProps[]>([])
  const [sharedDigital, setSharedDigital] = useState<DigitalProps[]>([])
  const [videos, setVideos] = useState<VideoProps[]>([])
  const [sharedVideos, setSharedVideos] = useState<VideoProps[]>([])
  const [audios, setAudios] = useState<AudioProps[]>([])
  const [sharedAudios, setSharedAudios] = useState<AudioProps[]>([])
  const [graphics, setGraphics] = useState<GraphicsMaterialProps[]>([])
  const [photos, setPhotos] = useState<PhotoProps[]>([])

  useEffect(() => {
    api.get(`/material-digitals/?userId=${user.id}`).then((response) => {
      setDigital(response.data)
    })
  }, [user])

  useEffect(() => {
    api
      .get(`/material-digitals/shared/?partyId=${user.campaign?.partyId}`)
      .then((response) => {
        setSharedDigital(response.data)
      })
  }, [user])

  useEffect(() => {
    api.get(`/videos/?userId=${user.id}`).then((response) => {
      setVideos(response.data)
    })
  }, [user])

  useEffect(() => {
    api
      .get(`/videos/shared/?partyId=${user.campaign?.partyId}`)
      .then((response) => {
        setSharedVideos(response.data)
      })
  }, [user])

  useEffect(() => {
    api.get(`/audios/?userId=${user.id}`).then((response) => {
      setAudios(response.data)
    })
  }, [user])

  useEffect(() => {
    api
      .get(`/audios/shared/?partyId=${user.campaign?.partyId}`)
      .then((response) => {
        setSharedAudios(response.data)
      })
  }, [user])

  useEffect(() => {
    api.get(`/material-graphics/?userId=${user.id}`).then((response) => {
      setGraphics(response.data)
    })
  }, [user])

  useEffect(() => {
    api.get(`/photos/?userId=${user.id}`).then((response) => {
      setPhotos(response.data)
    })
  }, [user])

  return (
    <>
      <Heading fontSize="xl" mb={7}>
        Materiais de campanha disponíveis
      </Heading>

      <SimpleGrid minChildWidth="300px" spacing={6}>
        <Panel
          title="Materiais digitais"
          icon={<FiInstagram color="#E74C3C" />}
        >
          <SimpleGrid columns={2}>
            <Stat textAlign="center">
              <StatNumber fontSize={50} fontWeight="bold" color="gray.700">
                {digital.length}
              </StatNumber>
              <StatHelpText>Personalizados</StatHelpText>
            </Stat>

            <Stat textAlign="center">
              <StatNumber fontSize={50} fontWeight="bold" color="gray.700">
                {sharedDigital.length}
              </StatNumber>
              <StatHelpText>Temáticos</StatHelpText>
            </Stat>
          </SimpleGrid>
        </Panel>

        <Panel title="Vídeos" icon={<FiVideo color="#9B59B6" />}>
          <SimpleGrid columns={2}>
            <Stat textAlign="center">
              <StatNumber fontSize={50} fontWeight="bold" color="gray.700">
                {videos.length}
              </StatNumber>
              <StatHelpText>Personalizados</StatHelpText>
            </Stat>

            <Stat textAlign="center">
              <StatNumber fontSize={50} fontWeight="bold" color="gray.700">
                {sharedVideos.length}
              </StatNumber>
              <StatHelpText>Temáticos</StatHelpText>
            </Stat>
          </SimpleGrid>
        </Panel>

        <Panel title="Áudios" icon={<FiHeadphones color="#1abc9c" />}>
          <SimpleGrid columns={2}>
            <Stat textAlign="center">
              <StatNumber fontSize={50} fontWeight="bold" color="gray.700">
                {audios.length}
              </StatNumber>
              <StatHelpText>Personalizados</StatHelpText>
            </Stat>

            <Stat textAlign="center">
              <StatNumber fontSize={50} fontWeight="bold" color="gray.700">
                {sharedAudios.length}
              </StatNumber>
              <StatHelpText>Temáticos</StatHelpText>
            </Stat>
          </SimpleGrid>
        </Panel>

        <Panel title="Materiais gráficos" icon={<FiImage color="#3498DB" />}>
          <Stat textAlign="center">
            <StatNumber fontSize={60} fontWeight="bold">
              {graphics.length}
            </StatNumber>
            <StatHelpText>Disponíveis</StatHelpText>
          </Stat>
        </Panel>

        <Panel title="Fotos" icon={<FiImage color="#f39c12" />}>
          <Stat textAlign="center">
            <StatNumber fontSize={50} fontWeight="bold" color="gray.700">
              {photos.length}
            </StatNumber>
            <StatHelpText>Disponíveis</StatHelpText>
          </Stat>
        </Panel>
        <Panel title="Website" icon={<FiMonitor color="#9b59b6" />}>
          <Stat textAlign="center">
            <StatNumber fontSize={50} fontWeight="bold" color="gray.700">
              1
            </StatNumber>
            <StatHelpText>Disponível</StatHelpText>
          </Stat>
        </Panel>
      </SimpleGrid>
    </>
  )
}

export default Statistics
