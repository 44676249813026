import * as S from './styles'

interface TableProps {
  children: React.ReactNode
}

const Table = ({ children }: TableProps) => {
  return (
    <div style={{ overflowX: 'auto' }}>
      <S.Wrapper>{children}</S.Wrapper>
    </div>
  )
}

export default Table
