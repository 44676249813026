import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import { Form, Formik } from 'formik'
import Input from 'components/Input'
import Button from 'components/Button'
import { useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { ThemeProps } from 'App.d'
import api from 'services/api'
import { useToast } from '@chakra-ui/react'

const EditTheme = () => {
  const { id } = useParams()
  const toast = useToast()
  const [theme, setTheme] = useState<ThemeProps>({} as ThemeProps)
  const [loading, setLoading] = useState(false)
  const [formLoading, setFormLoading] = useState(false)

  const handleSubmit = useCallback(
    async (values: ThemeProps) => {
      try {
        await api.put(`/themes/${id}`, values)

        toast({
          description: 'Tema editado com sucesso',
          status: 'success'
        })

        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    },
    [toast, id]
  )

  useEffect(() => {
    setFormLoading(true)

    api.get(`/themes/${id}`).then((response) => {
      setTheme(response.data)
      setFormLoading(false)
    })
  }, [id])

  return (
    <Layout containerMaxWidth="600">
      <Panel title="Editar tema">
        {formLoading ? (
          'Carregando formulário'
        ) : (
          <Formik
            initialValues={theme}
            onSubmit={(values) => {
              setLoading(true)
              handleSubmit(values)
            }}
            enableReinitialize
          >
            {({ values, handleChange, handleBlur }) => (
              <Form>
                <Input
                  name="title"
                  value={values.title}
                  placeholder="Nome do tema"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ marginBottom: '20px' }}
                />

                <Button type="submit" loading={loading}>
                  Enviar formulário
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Panel>
    </Layout>
  )
}

export default EditTheme
