import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import { Form, Formik } from 'formik'
import InputFile from 'components/InputFile'
import Button from 'components/Button'
import { FlagProps } from 'App.d'
import { useCallback, useEffect, useState } from 'react'
import Input from 'components/Input'
import api from 'services/api'
import { useParams } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'

const EditFlag = () => {
  const { id } = useParams()
  const toast = useToast()
  const [flag, setFlag] = useState<FlagProps>({} as FlagProps)
  const [loading, setLoading] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [preview, setPreview] = useState<string | null>(null)

  const handleSubmit = useCallback(
    async (values: FlagProps) => {
      try {
        const form = new FormData()

        if (values.image && values.image !== flag.image)
          form.append('image', values.image)
        if (values.description) form.append('description', values.description)
        form.append('title', values.title)

        await api.put(`/flags/${id}`, form)

        toast({
          description: 'Bandeira editada com sucesso',
          status: 'success'
        })

        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    },
    [toast, flag.image, id]
  )

  useEffect(() => {
    setFormLoading(true)

    api.get(`/flags/${id}`).then((response) => {
      setFlag(response.data)
      setPreview(response.data.imagePath)
      setFormLoading(false)
    })
  }, [id])

  return (
    <Layout containerMaxWidth="600">
      <Panel title="Editar bandeira">
        {formLoading ? (
          'Carregando formulário'
        ) : (
          <Formik
            initialValues={flag}
            onSubmit={(values) => {
              setLoading(true)
              handleSubmit(values)
            }}
            enableReinitialize
          >
            {({ values, handleBlur, handleChange, setFieldValue }) => (
              <Form>
                <div style={{ marginBottom: '20px' }}>
                  {preview && <img src={preview} width="250" />}
                </div>

                <InputFile
                  label="Imagem"
                  name="image"
                  onBlur={handleBlur}
                  onChange={(event) => {
                    if (event.target.files) {
                      const file = event.target.files[0]
                      setPreview(URL.createObjectURL(file))
                      setFieldValue('image', file)
                    }
                  }}
                  style={{ marginBottom: '20px' }}
                />

                <Input
                  name="title"
                  placeholder="Título"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ marginBottom: '20px' }}
                />

                <Input
                  name="description"
                  placeholder="Descrição"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ marginBottom: '20px' }}
                />

                <Button type="submit" loading={loading}>
                  Enviar formulário
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Panel>
    </Layout>
  )
}

export default EditFlag
