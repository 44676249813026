import React, { SelectHTMLAttributes, useEffect, useState } from 'react'
import { MdArrowDownward } from 'react-icons/md'

import * as S from './styles'

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  validationError?: string | null | undefined
}

const Select = ({
  children,
  defaultValue,
  style,
  placeholder,
  validationError = null,
  ...rest
}: SelectProps) => {
  const [focused, setFocused] = useState(false)

  const handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const select = e.currentTarget
    setFocused(!!select.value)
  }

  useEffect(() => {
    if (defaultValue) {
      setFocused(true)
    }
  }, [defaultValue])

  return (
    <S.Wrapper style={style} className={validationError ? 'danger' : ''}>
      {placeholder && <S.SelectPlaceholder>{placeholder}</S.SelectPlaceholder>}
      <S.WrapperInner focused={focused}>
        <select
          onChange={(e) => handleChange(e)}
          defaultValue={defaultValue}
          {...rest}
        >
          {children}
        </select>
        <S.SelectArrowWrapper>
          <MdArrowDownward />
        </S.SelectArrowWrapper>
      </S.WrapperInner>
      {validationError && <S.HelpText>{validationError}</S.HelpText>}
    </S.Wrapper>
  )
}

export default Select
