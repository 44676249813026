import React, { useState } from 'react'
import Hamburger from 'hamburger-react'

import Header from 'components/Header'
import Sidebar from 'components/Sidebar'

import * as S from './styles'
import { useCampaignInfo } from 'context/CampaignInfoContext'
import { loggedUserIsAdmin } from 'services/helpers'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle
} from '@chakra-ui/react'

interface LayoutProps {
  containerMaxWidth?: string
  children: React.ReactNode
  heroImg?: string
  heroVideo?: string
  heroAudio?: string
}

const Layout = ({
  containerMaxWidth = '1200',
  children,
  heroImg
}: LayoutProps) => {
  const isAdmin = loggedUserIsAdmin()
  const { completedCampaignInfo } = useCampaignInfo()
  const [sidebarMobileOpen, setSidebarMobileOpen] = useState(false)

  return (
    <S.Wrapper>
      <S.LayoutHeader>
        <Header
          mobileMenu={
            <Hamburger
              size={24}
              toggled={sidebarMobileOpen}
              toggle={setSidebarMobileOpen}
            />
          }
        />
      </S.LayoutHeader>
      <S.LayoutSidebar mobileOpen={sidebarMobileOpen}>
        <Sidebar />
      </S.LayoutSidebar>

      {!isAdmin && !completedCampaignInfo && (
        <Alert
          status="warning"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
          marginBottom="20px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg" color="orange.500">
            Precisamos de sua atenção
          </AlertTitle>
          <AlertDescription maxWidth="lg" fontSize="sm">
            Para que possamos iniciar a produção dos seus materiais, precisamos
            que você preencha todas as suas informações de campanha.
          </AlertDescription>
        </Alert>
      )}

      {!!heroImg && <S.LayoutHero background={heroImg} />}

      <S.LayoutContainer containerMaxWidth={containerMaxWidth}>
        {children}
      </S.LayoutContainer>
    </S.Wrapper>
  )
}

export default Layout
