import styled from 'styled-components'

export const Wrapper = styled.div``

export const WrapperInput = styled.div`
  display: flex;
  padding: 6px;
  align-items: center;
  border: 2px solid var(--gray-500);
  border-radius: 2px;

  button {
    margin-right: 10px;
  }
`

export const Label = styled.label`
  display: block;
  margin-bottom: 6px;
  font-size: 1rem;
  color: var(--gray-700);
`
