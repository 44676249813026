import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import { FiInstagram, FiTrash } from 'react-icons/fi'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { DigitalProps, OfficeProps, PartyProps, ThemeProps } from 'App.d'
import api, { apiURL } from 'services/api'
import Button from 'components/Button'
import { loggedUserIsAdmin } from 'services/helpers'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Grid,
  GridItem,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
  useToast
} from '@chakra-ui/react'
import Card from 'components/Card'

const SharedDigital = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const isAdmin = loggedUserIsAdmin()
  const [searchParams] = useSearchParams()
  const [offices, setOffices] = useState<OfficeProps[]>([])
  const [materials, setMaterials] = useState<DigitalProps[]>([])
  const [selectedMaterials, setSelectedMaterials] = useState<DigitalProps[]>([])
  const [parties, setParties] = useState<PartyProps[]>([])
  const [themes, setThemes] = useState<ThemeProps[]>([])
  const [partyId, setPartyId] = useState<string | null>(() => {
    return searchParams.get('partyId') ? searchParams.get('partyId') : null
  })

  const handleDelete = useCallback(
    async (materialId: string | undefined) => {
      try {
        if (confirm('Tem certeza que deseja deletar?')) {
          const response = await api.delete(`/material-digitals/${materialId}`)

          if (response.status === 200) {
            toast({
              description: 'Material deletado com sucesso',
              status: 'success'
            })

            setMaterials(
              materials.filter((material) => material.id !== materialId)
            )
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    [toast, materials]
  )

  const filterMaterialByThemeAndOffice = (
    themeId: string | undefined,
    officeId: string | undefined
  ) => {
    return selectedMaterials.filter(
      (material) =>
        material.themeId === themeId && material.category === officeId
    )
  }

  useEffect(() => {
    api.get('/offices').then((response) => {
      setOffices(response.data)
      console.log('offices', response.data)
    })
  }, [])

  useEffect(() => {
    api.get('/themes').then((response) => {
      setThemes(response.data)
    })
  }, [])

  useEffect(() => {
    api.get('/parties').then((response) => {
      setParties(response.data)
    })
  }, [])

  useEffect(() => {
    api.get(`/material-digitals/shared`).then((response) => {
      setMaterials(response.data)
      console.log('material', response.data)
    })
  }, [])

  useEffect(() => {
    if (searchParams.get('partyId') !== '') {
      setSelectedMaterials(
        materials.filter((material) => {
          if (material.partyId) {
            return material.partyId === searchParams.get('partyId')
          }
        })
      )

      setPartyId(searchParams.get('partyId'))
    }
  }, [materials, searchParams])

  return (
    <Layout>
      {isAdmin && (
        <Link to={`/digital/upload`}>
          <Button
            size="small"
            style={{ marginBottom: '20px', marginLeft: 'auto' }}
          >
            Adicionar material
          </Button>
        </Link>
      )}

      <Panel
        title="Materiais digitais compartilhados"
        icon={<FiInstagram color="#E74C3C" />}
      >
        <div style={{ marginBottom: '10px', width: '350px' }}>
          <Select
            placeholder="Escolha o partido"
            value={searchParams.get('partyId') || ''}
            size="sm"
            colorScheme="green"
            onChange={(event) => {
              const selectedPartyId = event.currentTarget.value

              selectedPartyId === ''
                ? navigate(`/digital/shared`)
                : navigate(`/digital/shared?partyId=${selectedPartyId}`)
            }}
          >
            {parties.map((party) => (
              <option key={party.id} value={party.id}>
                {party.name} ({party.initials})
              </option>
            ))}
          </Select>
        </div>

        {partyId && (
          <Tabs colorScheme="green">
            <TabList>
              {offices.map((office) => (
                <Tab key={office.id}>{office.title}</Tab>
              ))}
            </TabList>

            <TabPanels>
              {offices.map((office) => (
                <TabPanel key={office.id} paddingStart="0" paddingEnd="0">
                  <Tabs variant="soft-rounded" colorScheme="green">
                    <TabList>
                      {themes.map((theme) => (
                        <Tab key={theme.id} fontSize="sm">
                          {theme.title}
                        </Tab>
                      ))}
                    </TabList>
                    <TabPanels>
                      {themes.map((theme) => (
                        <TabPanel
                          key={theme.id}
                          paddingStart="0"
                          paddingEnd="0"
                        >
                          <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                            {filterMaterialByThemeAndOffice(theme.id, office.id)
                              .length > 0 ? (
                              filterMaterialByThemeAndOffice(
                                theme.id,
                                office.id
                              ).map((material) => (
                                <GridItem
                                  key={material.id}
                                  colSpan={{ base: 12, sm: 6, md: 6, lg: 3 }}
                                >
                                  <Card
                                    title={material.title}
                                    imgUrl={material.materialPath}
                                    footer={
                                      isAdmin ? (
                                        <div style={{ display: 'flex' }}>
                                          <Tooltip
                                            label="Deletar"
                                            hasArrow
                                            placement="top"
                                          >
                                            <Link to="#">
                                              <FiTrash
                                                size={25}
                                                color="#E74C3C"
                                                onClick={() =>
                                                  handleDelete(material.id)
                                                }
                                              />
                                            </Link>
                                          </Tooltip>
                                        </div>
                                      ) : (
                                        <a
                                          href={`${apiURL}/downloads/?type=material-digitals&id=${material.id}`}
                                        >
                                          <Button block>Download</Button>
                                        </a>
                                      )
                                    }
                                  />
                                </GridItem>
                              ))
                            ) : (
                              <GridItem colSpan={3}>
                                <Alert status="info">
                                  <AlertIcon />
                                  <AlertDescription fontSize="sm">
                                    Nenhum material encontrado
                                  </AlertDescription>
                                </Alert>
                              </GridItem>
                            )}
                          </Grid>
                        </TabPanel>
                      ))}
                    </TabPanels>
                  </Tabs>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        )}
      </Panel>
    </Layout>
  )
}

export default SharedDigital
