import styled from 'styled-components'

interface WrapperProps {
  withFooter: boolean
}

export const Wrapper = styled.main<WrapperProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.09);
  border-radius: 1px;
`

export const CardBody = styled.div`
  padding: 20px;
`

export const CardTitle = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  color: var(--gray-800);
`

export const CardDescription = styled.p`
  margin: 10px 0 0 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--gray-700);
`

export const CardFooter = styled.div`
  padding: 20px;
  padding-top: 0;
  width: 100%;
`

export const Downloaded = styled.div`
  display: flex;
  font-size: 0.8125rem;
  margin-top: 10px;

  > svg {
    margin-right: 5px;
  }
`
