import { ChangeEvent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Layout from 'templates/Layout'
import { FiVideo } from 'react-icons/fi'
import Panel from 'components/Panel'
import { Grid, GridItem, Input, Tooltip } from '@chakra-ui/react'
import Table from 'components/Table'
import api from 'services/api'
import { UserProps } from 'App.d'
import Button from 'components/Button'

interface VideoProps {
  id: string
  userId: string
  title: string
  videoPath: string
}

const Videos = () => {
  const [users, setUsers] = useState<UserProps[]>([])
  const [filteredUsers, setFilteredUsers] = useState<UserProps[]>([])
  const [videos, setVideos] = useState<VideoProps[]>([])

  const getVideosCount = (userId: string) => {
    return videos.filter((video) => video.userId == userId).length
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase()

    setFilteredUsers(
      users.filter((user) => {
        if (value === '') return users

        return (
          user.fullName.toLowerCase().includes(value) ||
          user.campaign?.electoralName.toLowerCase().includes(value) ||
          user.campaign?.party?.initials.toLowerCase().includes(value)
        )
      })
    )
  }

  useEffect(() => {
    api.get('/videos').then((response) => {
      setVideos(response.data)
    })
  }, [])

  useEffect(() => {
    api.get('/users').then((response) => {
      setUsers(response.data.filter((user: UserProps) => user.type === 'user'))
      setFilteredUsers(
        response.data.filter((user: UserProps) => user.type === 'user')
      )
    })
  }, [])

  return (
    <Layout>
      <Link to={`/videos/shared`}>
        <Button
          size="small"
          style={{ marginBottom: '20px', marginLeft: 'auto' }}
        >
          Vídeos compartilhados
        </Button>
      </Link>

      <Panel title="Videos" icon={<FiVideo color="#9B59B6" />}>
        <Grid
          templateColumns="repeat(3, 1fr)"
          gap={6}
          style={{ marginBottom: '20px' }}
        >
          <GridItem>
            <Input placeholder="Buscar" size="sm" onChange={handleSearch} />
          </GridItem>
        </Grid>

        <Table>
          <thead>
            <tr>
              <th>Usuário</th>
              <th>Nome de urna</th>
              <th>Partido</th>
              <th>Vídeos</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.id}>
                <td>{user.fullName}</td>
                <td>{user.campaign?.electoralName}</td>
                <td>{user.campaign?.party?.initials}</td>
                <td>{getVideosCount(user.id)} videos</td>
                <td style={{ width: '70px' }}>
                  <div style={{ display: 'flex' }}>
                    <Tooltip hasArrow placement="top" label="Gerenciar vídeos">
                      <Link to={`/videos/user/${user.id}`}>
                        <FiVideo size={20} color="#9B59B6" />
                      </Link>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Panel>
    </Layout>
  )
}

export default Videos
