import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import { Tooltip, useToast } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit, FiTrash } from 'react-icons/fi'
import Table from 'components/Table'
import { useCallback, useEffect, useState } from 'react'
import { PartyProps } from 'App.d'
import api from 'services/api'
import Button from 'components/Button'

const Parties = () => {
  const toast = useToast()
  const [parties, setParties] = useState<PartyProps[]>([])

  const handleDelete = useCallback(
    async (partyId: string) => {
      try {
        if (confirm('Tem certeza que deseja deletar?')) {
          await api.delete(`/parties/${partyId}`)

          toast({
            description: 'Partido deletado com sucesso',
            status: 'success'
          })

          setParties(parties.filter((party) => party.id !== partyId))
        }
      } catch (error) {
        console.log(error)
      }
    },
    [toast, parties]
  )

  useEffect(() => {
    api.get('/parties').then((response) => {
      setParties(response.data)
    })
  }, [])

  return (
    <Layout>
      <Link to={`/parties/add`}>
        <Button
          size="small"
          style={{ marginBottom: '20px', marginLeft: 'auto' }}
        >
          Cadastrar partido
        </Button>
      </Link>

      <Panel title="Partidos">
        <Table>
          <thead>
            <tr>
              <th>Partido</th>
              <th>Sigla</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {parties.map((party) => (
              <tr key={party.id}>
                <td>{party.name}</td>
                <td>{party.initials}</td>
                <td style={{ width: '70px' }}>
                  <div style={{ display: 'flex' }}>
                    <Tooltip hasArrow placement="top" label="Editar">
                      <Link
                        to={`/parties/${party.id}`}
                        style={{ marginRight: '5px' }}
                      >
                        <FiEdit size={20} color="#3498DB" />
                      </Link>
                    </Tooltip>

                    <Tooltip hasArrow placement="top" label="Deletar">
                      <Link to="#" onClick={() => handleDelete(party.id)}>
                        <FiTrash size={20} color="#E74C3C" />
                      </Link>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Panel>
    </Layout>
  )
}

export default Parties
