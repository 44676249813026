import styled from 'styled-components'

interface SidebarButtonProps {
  iconColor?: string
}

export const Wrapper = styled.div<SidebarButtonProps>`
  display: flex;
  padding: 30px 25px 30px 21px;
  border-bottom: 1px solid var(--gray-300);
  border-left: 4px solid transparent;
  text-decoration: none;
  &:hover {
    background: var(--gray-100);
    border-left: 4px solid ${(props) => props.iconColor};
  }
  > svg {
    flex-shrink: 0;
    width: 26px;
    height: 26px;
    margin-right: 10px;
    stroke-width: 1.5px;
    color: ${(props) => props.iconColor};
  }
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-size: 1rem;
    font-weight: 600;
    color: var(--gray-800);
    svg {
      margin-left: 5px;
      width: 26px;
      height: 26px;
      color: #fff;
    }
  }
  p {
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    color: var(--gray-600);
    line-height: 18px;
  }
`

export const Content = styled.div``

export const Tag = styled.div`
  display: inline-block;
  margin-top: 7px;
`
