import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import Table from 'components/Table'
import { Tooltip, useToast } from '@chakra-ui/react'
import { FiEdit, FiTrash } from 'react-icons/fi'
import { ThemeProps } from 'App.d'
import api from 'services/api'
import Button from 'components/Button'

const Themes = () => {
  const toast = useToast()
  const [themes, setThemes] = useState<ThemeProps[]>([])

  const handleDelete = useCallback(
    async (themeId: string) => {
      try {
        if (confirm('Tem certeza que deseja deletar?')) {
          await api.delete(`/themes/${themeId}`)

          toast({
            description: 'Tema deletado com sucesso',
            status: 'success'
          })

          setThemes(themes.filter((theme) => theme.id !== themeId))
        }
      } catch (error) {
        console.log(error)
      }
    },
    [toast, themes]
  )

  useEffect(() => {
    api.get('/themes').then((response) => {
      setThemes(response.data)
    })
  }, [])

  return (
    <Layout>
      <Link to="/themes/add">
        <Button
          size="small"
          style={{ marginBottom: '20px', marginLeft: 'auto' }}
        >
          Novo tema
        </Button>
      </Link>

      <Panel title="Temas">
        <Table>
          <thead>
            <tr>
              <th>Tema</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {themes.map((theme) => (
              <tr key={theme.id}>
                <td>{theme.title}</td>
                <td style={{ width: '80px' }}>
                  <div style={{ display: 'flex' }}>
                    <Tooltip label="Editar" placement="top" hasArrow>
                      <Link to={`/themes/${theme.id}`}>
                        <FiEdit size={20} color="#3498DB" />
                      </Link>
                    </Tooltip>

                    <Tooltip label="Deletar" placement="top" hasArrow>
                      <Link to="#" style={{ marginLeft: '6px' }}>
                        <FiTrash
                          size={20}
                          color="#E74C3C"
                          onClick={() => theme.id && handleDelete(theme.id)}
                        />
                      </Link>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Panel>
    </Layout>
  )
}

export default Themes
