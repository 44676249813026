import * as Yup from 'yup'
import ValidationErrorsMessages from 'utils/validationErrorsMessages'

const formValidation = Yup.object().shape({
  email: Yup.string()
    .required(ValidationErrorsMessages.required)
    .email(ValidationErrorsMessages.email),
  password: Yup.string().required(ValidationErrorsMessages.required)
})

export default formValidation
