import { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Layout from 'templates/Layout'
import Button from 'components/Button'
import Panel from 'components/Panel'
import api, { apiURL } from 'services/api'
import { FiEdit, FiHeadphones, FiTrash } from 'react-icons/fi'
import {
  Grid,
  GridItem,
  useToast,
  Tooltip,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Select
} from '@chakra-ui/react'
import { AudioProps, OfficeProps, PartyProps } from 'App.d'
import { loggedUserIsAdmin } from 'services/helpers'
import CardAudio from 'components/CardAudio'

const SharedAudios = () => {
  const toast = useToast()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [offices, setOffices] = useState<OfficeProps[]>([])
  const [audios, setAudios] = useState<AudioProps[]>([])
  const [parties, setParties] = useState<PartyProps[]>([])
  const [partyId, setPartyId] = useState<string | null>(() => {
    return searchParams.get('partyId') ? searchParams.get('partyId') : null
  })

  const handleDelete = useCallback(
    async (audioId: string) => {
      try {
        if (confirm('Tem certeza que deseja excluir?')) {
          const response = await api.delete(`/audios/${audioId}`)

          if (response.status === 200) {
            toast({
              description: 'O áudio foi excluído com sucesso',
              status: 'success'
            })

            setAudios(audios.filter((audio) => audio.id !== audioId))
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    [toast, audios]
  )

  useEffect(() => {
    api.get('/offices').then((response) => {
      setOffices(response.data)
    })
  }, [])

  useEffect(() => {
    if (searchParams.get('partyId') !== '') {
      api
        .get(`/audios/shared/?partyId=${searchParams.get('partyId')}`)
        .then((response) => {
          setAudios(response.data)
        })

      setPartyId(searchParams.get('partyId'))
    }
  }, [searchParams])

  useEffect(() => {
    api.get('/parties').then((response) => {
      setParties(response.data)
    })
  }, [])

  return (
    <Layout>
      {loggedUserIsAdmin() && (
        <Link to={`/audios/upload`}>
          <Button
            size="small"
            style={{ marginBottom: '20px', marginLeft: 'auto' }}
          >
            Adicionar Áudio
          </Button>
        </Link>
      )}

      <Panel
        title="Áudios compartilhados"
        icon={<FiHeadphones color="#1abc9c" />}
      >
        <div style={{ marginBottom: '10px', width: '350px' }}>
          <Select
            placeholder="Escolha o partido"
            value={searchParams.get('partyId') || ''}
            size="sm"
            colorScheme="green"
            onChange={(event) => {
              const selectedPartyId = event.currentTarget.value

              selectedPartyId === ''
                ? navigate(`/audios/shared`)
                : navigate(`/audios/shared?partyId=${selectedPartyId}`)
            }}
          >
            {parties.map((party) => (
              <option key={party.id} value={party.id}>
                {party.name} ({party.initials})
              </option>
            ))}
          </Select>
        </div>

        {partyId && (
          <Tabs colorScheme="green">
            <TabList>
              {offices.map((office) => (
                <Tab key={office.id}>{office.title}</Tab>
              ))}
            </TabList>

            <TabPanels>
              {offices.map((office) => (
                <TabPanel key={office.id} paddingStart="0" paddingEnd="0">
                  <Grid templateColumns="repeat(12, 1fr)" gap={6}>
                    {audios
                      .filter((audio) => audio.category === office.id)
                      .map((audio) => (
                        <GridItem
                          key={audio.id}
                          colSpan={{ base: 12, sm: 6, md: 6, lg: 3 }}
                        >
                          <CardAudio
                            audioUrl={audio.audioPath}
                            title={audio.title}
                            footer={
                              loggedUserIsAdmin() ? (
                                <div style={{ display: 'flex' }}>
                                  <Tooltip
                                    label="Editar"
                                    hasArrow
                                    placement="top"
                                  >
                                    <Link
                                      to={`/audios/${audio.id}`}
                                      style={{ marginRight: '8px' }}
                                    >
                                      <FiEdit size={25} color="#3498DB" />
                                    </Link>
                                  </Tooltip>

                                  <Tooltip
                                    label="Deletar"
                                    hasArrow
                                    placement="top"
                                  >
                                    <Link to="#">
                                      <FiTrash
                                        size={25}
                                        color="#E74C3C"
                                        onClick={() => {
                                          if (audio.id) {
                                            handleDelete(audio.id)
                                          }
                                        }}
                                      />
                                    </Link>
                                  </Tooltip>
                                </div>
                              ) : (
                                <a
                                  href={`${apiURL}/downloads/?type=audios&id=${audio.id}`}
                                >
                                  <Button size="small" block>
                                    Download
                                  </Button>
                                </a>
                              )
                            }
                          />
                        </GridItem>
                      ))}
                  </Grid>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        )}
      </Panel>
    </Layout>
  )
}

export default SharedAudios
