import { ReactNode, useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import * as S from './styles'

export type PanelProps = {
  icon?: JSX.Element
  iconColor?: string
  title?: string
  collapsible?: boolean
  defaultClosed?: boolean
  children?: ReactNode
  style?: React.CSSProperties
}

const Panel = ({
  icon,
  iconColor,
  title,
  collapsible = false,
  defaultClosed = false,
  children,
  style
}: PanelProps) => {
  const [closed, setClosed] = useState(defaultClosed)

  return (
    <S.Wrapper style={style}>
      {!!title && (
        <S.PanelHeader
          iconColor={iconColor}
          collapsible={collapsible}
          onClick={() => collapsible && setClosed(!closed)}
          style={collapsible ? { cursor: 'pointer' } : {}}
        >
          {icon}
          <S.PanelTitle>{title}</S.PanelTitle>
          {collapsible && (
            <div className="panel-arrow">
              {closed ? <FiChevronUp size={20} /> : <FiChevronDown size={20} />}
            </div>
          )}
        </S.PanelHeader>
      )}
      <S.PanelContent closed={closed}>{children}</S.PanelContent>
    </S.Wrapper>
  )
}

export default Panel
