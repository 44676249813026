import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import InputFile from 'components/InputFile'
import { FiImage } from 'react-icons/fi'
import { useCallback, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import Select from 'components/Select'
import api from 'services/api'
import { useNavigate, useParams } from 'react-router-dom'
import { UserProps } from 'App.d'
import { Alert, AlertIcon, useToast } from '@chakra-ui/react'
import { ProgressBar } from 'react-bootstrap'
import Button from 'components/Button'

interface GraphicsMaterialProps {
  userId: string
  title: string
  material: string
}

const AddGraphicsMaterial = () => {
  const { id } = useParams()
  const toast = useToast()
  const navigate = useNavigate()
  const [user, setUser] = useState<UserProps>({} as UserProps)
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(false)

  const [data] = useState({
    userId: id ? id : '',
    title: '',
    material: ''
  })

  const handleSubmit = useCallback(
    async (values: GraphicsMaterialProps) => {
      try {
        const form = new FormData()

        form.append('userId', values.userId)
        form.append('title', values.title)
        form.append('material', values.material)

        const response = await api.post('/material-graphics', form, {
          onUploadProgress: (data) => {
            setProgress(Math.round((100 * data.loaded) / data.total))
          }
        })

        if (response.request.status === 200) {
          toast({
            description: 'O material foi cadastrado com sucesso',
            status: 'success'
          })
          setLoading(false)
          navigate(`/graphics-material/user/${values.userId}`)
        }
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    },
    [toast, navigate]
  )

  useEffect(() => {
    api.get(`/users/${id}`).then((response) => {
      setUser(response.data)
    })
  }, [id])

  return (
    <Layout containerMaxWidth="600">
      <Panel
        title="Adicionar novo material gráfico"
        icon={<FiImage color="#3498DB" />}
      >
        <Formik
          initialValues={data}
          onSubmit={(values) => {
            setLoading(true)
            handleSubmit(values)
          }}
        >
          {({ values, handleBlur, handleChange, setFieldValue }) => (
            <Form>
              <Select
                name="userId"
                placeholder="Candidato(a)"
                defaultValue={id ? id : values.userId}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{ marginBottom: '20px' }}
              >
                <option
                  value={user.id}
                >{`${user.fullName} (${user.campaign?.electoralName})`}</option>
              </Select>

              <Select
                name="title"
                placeholder="Tipo do material"
                defaultValue={values.title}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{ marginBottom: '20px' }}
              >
                <option value="">Escolha o tipo de material</option>
                <option value="Arte para Santinho">Arte para Santinho</option>
                <option value="Arte para Praguinha">Arte para Praguinha</option>
                <option value="Arte para Bandeira">Arte para Bandeira</option>
                <option value="Arte para Camiseta">Arte para Camiseta</option>
              </Select>

              <InputFile
                name="material"
                label="Material"
                onBlur={handleBlur}
                onChange={(event) => {
                  if (event.target.files) {
                    const file = event.target.files[0]
                    setFieldValue('material', file)
                  }
                }}
                style={{ marginBottom: '20px' }}
              />

              {loading && (
                <div style={{ marginBottom: '20px' }}>
                  <Alert
                    status="warning"
                    fontSize="sm"
                    style={{ marginBottom: '10px' }}
                  >
                    <AlertIcon />
                    Por favor, aguarde. O upload está em andamento, não feche
                    esta tela até a conclusão.
                  </Alert>
                  <ProgressBar
                    style={{ borderRadius: '2px' }}
                    now={progress}
                    label={`${progress}%`}
                  />
                </div>
              )}

              <Button type="submit" loading={loading}>
                Enviar
              </Button>
            </Form>
          )}
        </Formik>
      </Panel>
    </Layout>
  )
}

export default AddGraphicsMaterial
