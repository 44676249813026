import React, { InputHTMLAttributes, useState, useEffect, useRef } from 'react'
import InputMask from 'react-input-mask'

import { Wrapper, InputWrapper, InputPlaceholder, HelpText } from './styles'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: 'text' | 'password' | 'number' | 'email'
  validationError?: string | null | undefined
  mask?: string
}

const Input: React.FC<InputProps> = ({
  type = 'text',
  placeholder,
  value,
  style,
  validationError = null,
  mask,
  onChange,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [focused, setFocused] = useState(false)

  function handleClick() {
    setFocused(true)
    if (inputRef.current !== null) {
      inputRef.current.focus()
    }
  }

  function handleBlur() {
    if (inputRef.current && !inputRef.current.value) {
      setFocused(false)
    }
  }

  useEffect(() => {
    if (value) {
      setFocused(true)
    }
  }, [value])

  return (
    <Wrapper style={style}>
      <InputMask
        mask={mask || ''}
        onChange={onChange}
        onClick={handleClick}
        onBlur={handleBlur}
        onFocus={() => setFocused(true)}
      >
        <InputWrapper focused={focused}>
          <input
            ref={inputRef}
            className={validationError ? 'danger' : ''}
            defaultValue={value || ''}
            type={type}
            {...rest}
          />
          <InputPlaceholder>{placeholder}</InputPlaceholder>
        </InputWrapper>
      </InputMask>
      {validationError && <HelpText>{validationError}</HelpText>}
    </Wrapper>
  )
}

export default Input
