import { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Layout from 'templates/Layout'
import Button from 'components/Button'
import Panel from 'components/Panel'
import api, { apiURL } from 'services/api'
import { FiEdit, FiHeadphones, FiTrash } from 'react-icons/fi'
import { Grid, GridItem, useToast, Tooltip } from '@chakra-ui/react'
import { UserProps, AudioProps } from 'App.d'
import { loggedUserIsAdmin } from 'services/helpers'
import CardAudio from 'components/CardAudio'

const UserAudios = () => {
  const { id } = useParams()
  const toast = useToast()
  const isAdmin = loggedUserIsAdmin()
  const [user, setUser] = useState<UserProps>({} as UserProps)
  const [audios, setAudios] = useState<AudioProps[]>([])
  const [sharedAudios, setSharedAudios] = useState<AudioProps[]>([])

  const handleDelete = useCallback(
    async (audioId: string) => {
      try {
        if (confirm('Tem certeza que deseja excluir?')) {
          const response = await api.delete(`/audios/${audioId}`)

          if (response.status === 200) {
            toast({
              description: 'O áudio foi excluído com sucesso',
              status: 'success'
            })

            setAudios(audios.filter((audio) => audio.id !== audioId))
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    [toast, audios]
  )

  const setDownloaded = (audioId: string | undefined) => {
    const audio = audios.filter((item) => item.id === audioId)[0]
    const audioDownaloded = { ...audio, downloaded: true }
    const allAudios = audios.map((item) => {
      return item.id === audioId ? audioDownaloded : item
    })
    setAudios(allAudios)
  }

  useEffect(() => {
    api.get(`/users/${id}`).then((response) => {
      setUser(response.data)
    })
  }, [id])

  useEffect(() => {
    api.get(`/audios/?userId=${id}`).then((response) => {
      setAudios(response.data)
    })
  }, [id])

  useEffect(() => {
    api
      .get(`/audios/shared/?partyId=${user.campaign?.partyId}`)
      .then((response) => {
        setSharedAudios(
          response.data.filter(
            (audio: AudioProps) => audio.category === user.campaign?.officeId
          )
        )
      })
  }, [user])

  return (
    <Layout>
      {isAdmin && (
        <Link to={`/audios/upload/${id}`}>
          <Button
            size="small"
            style={{ marginBottom: '20px', marginLeft: 'auto' }}
          >
            Adicionar Áudio
          </Button>
        </Link>
      )}

      <Panel
        title={
          isAdmin
            ? `Áudios do usuário ${user.fullName}`
            : 'Áudios personalizados'
        }
        icon={<FiHeadphones color="#1abc9c" />}
        style={{ marginBottom: '20px' }}
        collapsible
        defaultClosed
      >
        <Grid templateColumns="repeat(12, 1fr)" gap={6}>
          {audios.map((audio) => (
            <GridItem
              key={audio.id}
              colSpan={{ base: 12, sm: 6, md: 6, lg: 3 }}
            >
              <CardAudio
                audioUrl={audio.audioPath}
                title={audio.title}
                downloaded={audio.downloaded}
                footer={
                  loggedUserIsAdmin() ? (
                    <div style={{ display: 'flex' }}>
                      <Tooltip label="Editar" hasArrow placement="top">
                        <Link
                          to={`/audios/${audio.id}`}
                          style={{ marginRight: '8px' }}
                        >
                          <FiEdit size={25} color="#3498DB" />
                        </Link>
                      </Tooltip>

                      <Tooltip label="Deletar" hasArrow placement="top">
                        <Link to="#">
                          <FiTrash
                            size={25}
                            color="#E74C3C"
                            onClick={() => {
                              if (audio.id) {
                                handleDelete(audio.id)
                              }
                            }}
                          />
                        </Link>
                      </Tooltip>
                    </div>
                  ) : (
                    <a href={`${apiURL}/downloads/?type=audios&id=${audio.id}`}>
                      <Button
                        size="small"
                        block
                        onClick={() => setDownloaded(audio.id)}
                      >
                        Download
                      </Button>
                    </a>
                  )
                }
              />
            </GridItem>
          ))}
        </Grid>
      </Panel>

      {!isAdmin && (
        <Panel
          title="Áudios temáticos"
          icon={<FiHeadphones color="#1abc9c" />}
          collapsible
          defaultClosed
        >
          <Grid templateColumns="repeat(12, 1fr)" gap={6}>
            {sharedAudios.map((audio) => (
              <GridItem
                key={audio.id}
                colSpan={{ base: 12, sm: 6, md: 6, lg: 3 }}
              >
                <CardAudio
                  audioUrl={audio.audioPath}
                  title={audio.title}
                  footer={
                    <a href={`${apiURL}/downloads/?type=audios&id=${audio.id}`}>
                      <Button size="small" block>
                        Download
                      </Button>
                    </a>
                  }
                />
              </GridItem>
            ))}
          </Grid>
        </Panel>
      )}
    </Layout>
  )
}

export default UserAudios
