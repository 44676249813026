import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import InputFile from 'components/InputFile'
import Input from 'components/Input'
import { useNavigate, useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { PartyProps } from 'App.d'
import api from 'services/api'
import { Form, Formik } from 'formik'
import { HexColorPicker, HexColorInput } from 'react-colorful'
import Button from 'components/Button'
import { useToast } from '@chakra-ui/react'
import BackButton from 'components/BackButton'

const EditParty = () => {
  const { id } = useParams()
  const toast = useToast()
  const navigate = useNavigate()
  const [party, setParty] = useState<PartyProps>({} as PartyProps)
  const [loading, setLoading] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [color, setColor] = useState('#fff')
  const [logo, setLogo] = useState('')

  const handleSubmit = useCallback(
    async (values: PartyProps) => {
      try {
        const form = new FormData()

        form.append('name', values.name)
        form.append('initials', values.initials)
        form.append('slug', values.slug)

        if (values.themeColor) form.append('themeColor', values.themeColor)

        if (values.logo && values.logo !== party.logo) {
          console.log('alterar logo')
          form.append('logo', values.logo)
        }

        await api.put(`/parties/${id}`, form)

        toast({
          description: 'Partido editado com sucesso',
          status: 'success'
        })

        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    },
    [toast, party.logo, id]
  )

  useEffect(() => {
    setFormLoading(true)

    api.get(`/parties/${id}`).then((response) => {
      setParty(response.data)
      setFormLoading(false)
      setColor(response.data.themeColor)
      setLogo(response.data.logoPath)
    })
  }, [id])

  return (
    <Layout containerMaxWidth="600">
      <a onClick={() => navigate(-1)}>
        <BackButton label="Voltar" style={{ marginBottom: '20px' }} />
      </a>

      <Panel title="Editar partido">
        {formLoading ? (
          'Carregando formulário'
        ) : (
          <Formik
            initialValues={party}
            onSubmit={(values) => {
              setLoading(true)
              handleSubmit(values)
            }}
            enableReinitialize
          >
            {({ values, handleBlur, handleChange, setFieldValue }) => (
              <Form>
                <img
                  src={logo}
                  style={{ width: '200px', marginBottom: '20px' }}
                />

                <InputFile
                  label=""
                  name="logo"
                  onChange={(event) => {
                    if (event.target.files) {
                      setLogo(URL.createObjectURL(event.target.files[0]))
                      setFieldValue('logo', event.target.files[0])
                    }
                  }}
                  onBlur={handleBlur}
                  style={{ marginBottom: '20px' }}
                />

                <Input
                  value={values.name}
                  name="name"
                  placeholder="Nome"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ marginBottom: '20px' }}
                />

                <Input
                  value={values.initials}
                  name="initials"
                  placeholder="Sigla"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ marginBottom: '20px' }}
                />

                <Input
                  value={values.slug}
                  name="slug"
                  placeholder="Slug"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ marginBottom: '20px' }}
                />

                <div style={{ marginBottom: '20px' }}>
                  <p>Cor padrão</p>
                  <HexColorInput
                    color={values.themeColor || ''}
                    onChange={(color) => {
                      setFieldValue('themeColor', color)
                      setColor(color)
                    }}
                    style={{
                      marginBottom: '10px',
                      border: 'none',
                      background: `${color}`,
                      color: '#fff',
                      padding: '10px 20px',
                      fontSize: '1rem',
                      borderRadius: '2px'
                    }}
                  />

                  <HexColorPicker
                    color={values.themeColor || ''}
                    onChange={(color) => {
                      setFieldValue('themeColor', color)
                      setColor(color)
                    }}
                  />
                </div>

                <Button type="submit" loading={loading}>
                  Enviar formulário
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Panel>
    </Layout>
  )
}

export default EditParty
