import { InputHTMLAttributes, useState } from 'react'
import Dropzone, { Accept } from 'react-dropzone'
import { MdBackup } from 'react-icons/md'

import * as S from './styles'

type UploadZoneProps = {
  multiple?: boolean
  acceptFiles?: Accept
  helpText?: string
  onDrop(files: Accept[]): void
} & InputHTMLAttributes<HTMLInputElement>

const UploadZone = ({
  style,
  multiple = false,
  acceptFiles,
  helpText,
  onDrop,
  ...rest
}: UploadZoneProps) => {
  const [preview, setPreview] = useState<File[] | undefined>(undefined)

  return (
    <div style={style}>
      <Dropzone
        accept={acceptFiles}
        onDropAccepted={(acceptedFiles) => {
          setPreview(acceptedFiles)
        }}
        onDrop={onDrop}
        multiple={multiple}
      >
        {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
          <S.DropContainer
            {...getRootProps({ className: 'dropzone' })}
            dragAccept={isDragAccept}
            dragReject={isDragReject}
          >
            <MdBackup className="drop-container-icon" />
            {isDragAccept && <p>Solte seu arquivo para enviar</p>}
            {isDragReject && <p>Arquivo não suportado</p>}
            {!isDragAccept && !isDragReject && (
              <p>
                Arraste ou clique aqui para enviar o seu arquivo
                {helpText && <small>{helpText}</small>}
              </p>
            )}
            <input {...rest} {...getInputProps()} />
          </S.DropContainer>
        )}
      </Dropzone>

      {preview && (
        <S.Preview>
          {preview.map((prev, index) => (
            <img key={index} src={URL.createObjectURL(prev)} />
          ))}
        </S.Preview>
      )}
    </div>
  )
}

export default UploadZone
