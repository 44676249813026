import { ChangeEvent, InputHTMLAttributes, useRef, useState } from 'react'

import Button from 'components/Button'

import * as S from './styles'

export interface InputFileProps extends InputHTMLAttributes<HTMLInputElement> {
  validationError?: string | null | undefined
  label: string
}

const InputFile = ({ onChange, style, label, ...rest }: InputFileProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [filename, setFilename] = useState<string | null>(null)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(event)

    if (event.target.files) {
      const file = event.target.files[0]
      setFilename(file.name)
    }
  }

  return (
    <S.Wrapper style={style}>
      <S.Label>{label}</S.Label>
      <S.WrapperInput>
        <Button
          type="button"
          size="small"
          onClick={() => inputRef.current?.click()}
        >
          Carregar arquivo
        </Button>

        {filename ? filename : 'Nenhum arquivo carregado'}
      </S.WrapperInput>
      <input
        type="file"
        ref={inputRef}
        onChange={handleChange}
        {...rest}
        style={{ display: 'none' }}
      />
    </S.Wrapper>
  )
}

export default InputFile
