import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    outline: 0;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:focus {
      box-shadow: none !important;
    }
  }

  html {
    font-size: 16px;
  }

  html, body, #root {
    height: 100%;
  }

  :root {
    font-size: 16px;
    --gray-100: #f8f9fa;
    --gray-200: #e9ecef;
    --gray-300: #dee2e6;
    --gray-400: #ced4da;
    --gray-500: #adb5bd;
    --gray-600: #6c757d;
    --gray-700: #495057;
    --gray-800: #343a40;
    --gray-900: #212529;
  }

  h1, h2, h3, h4, h5 {
    margin-bottom: 25px;
    font-weight: 700;
    color: var(--gray-700);
  }
  p {
    display: block;
    margin-bottom: 15px;
    font-size: 1rem;
    font-weight: 400;
    color: var(--gray-600);
    line-height: 1.5625rem;
  }
  a {
    text-decoration: none;
    font-size: 1rem;
    font-weight: 400;
  }

  // Bootstrap
  .accordion {
    .accordion-button {
      background: #fbfbfb;
      color: var(--gray-800);
      font-weight: 600;
    }
  }

  // SunEditor styles
  .se-video-container {
    figure {
      position: relative;

      iframe {
        position: absolute
      }
    }
  }

  .se-image-container {
    img {
      width: 100%;
    }
  }
`

export default GlobalStyles
