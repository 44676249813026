import styled from 'styled-components'

interface WrapperProps {
  color?: string | undefined
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${(props) => props.color || props.theme.colors.primary};
`

export const FormWrapper = styled.div`
  padding: 30px;
  width: 100%;
  max-width: 360px;
  background: #fff;
  border-radius: 3px;
`

export const Logo = styled.img`
  display: block;
  width: 160px;
  margin: 0 auto 30px;
`
