import styled, { css } from 'styled-components'

import { TextareaProps } from '.'

interface InputWrapperProps extends TextareaProps {
  focused: boolean
  validationError?: string | null
}

export const Wrapper = styled.div``

export const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  display: flex;

  textarea {
    display: flex;
    flex: 1;
    padding: 10px 20px;
    border: 2px solid var(--gray-500);
    border-radius: 2px;
    font-size: 18px;
    color: var(--gray-700);
    height: 150px;

    &.danger {
      border-color: ${(props) => props.theme.colors.danger} !important;
    }
  }

  ${(props) =>
    props.focused &&
    css`
      textarea {
        border-color: ${props.theme.colors.success};
      }
      span {
        top: 0;
        left: 10px;
        font-size: 0.875rem;
        background: #fff;
        padding: 0 10px;
      }
    `}
`

export const InputPlaceholder = styled.span`
  position: absolute;
  top: 30px;
  left: 20px;
  margin-top: -9px;
  font-size: 1rem;
  line-height: 1.125rem;
  color: var(--gray-700);
  transition: all 200ms ease;
`

export const HelpText = styled.p`
  display: block;
  margin: 5px 0 0 0;
  padding: 0;
  font-size: 0.875rem;
  color: var(--gray-600);
`
