import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import { FiMonitor } from 'react-icons/fi'
import { Link, useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import api from 'services/api'
import { Form, Formik } from 'formik'
import InputFile from 'components/InputFile'
import Textarea from 'components/Textarea'
import Button from 'components/Button'
import { WebsiteProps } from 'App.d'
import { useToast } from '@chakra-ui/react'
import Input from 'components/Input'

const EditWebsite = () => {
  const { id } = useParams()
  const toast = useToast()
  const [website, setWebsite] = useState<WebsiteProps>({} as WebsiteProps)
  const [loading, setLoading] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [previewHero, setPreviewHero] = useState<string | null>(null)
  const [previewPhoto, setPreviewPhoto] = useState<string | null>(null)
  const [previewVideo, setPreviewVideo] = useState<string | null>(null)

  const handleSubmit = useCallback(
    async (values: WebsiteProps) => {
      try {
        const form = new FormData()

        if (values.card && values.card !== website.card)
          form.append('card', values.card)

        if (values.photo && values.photo !== website.photo)
          form.append('photo', values.photo)

        if (values.video && values.video !== website.video)
          form.append('video', values.video)

        values.bio && form.append('bio', values.bio)
        values.slug && form.append('slug', values.slug)

        values.facebook && form.append('facebook', values.facebook)
        values.instagram && form.append('instagram', values.instagram)
        values.youtube && form.append('youtube', values.youtube)
        values.tiktok && form.append('tiktok', values.tiktok)
        values.twitter && form.append('twitter', values.twitter)
        values.linkedin && form.append('linkedin', values.linkedin)

        await api.put(`/sites/${id}`, form)

        toast({
          description: 'Website atualizado com sucesso',
          status: 'success'
        })

        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    },
    [website, id, toast]
  )

  useEffect(() => {
    setFormLoading(true)

    api.get(`/sites/${id}`).then((response) => {
      setWebsite(response.data)
      setFormLoading(false)
      console.log(response.data)

      if (response.data.cardPath) setPreviewHero(response.data.cardPath)
      if (response.data.photoPath) setPreviewPhoto(response.data.photoPath)
      if (response.data.videoPath) setPreviewVideo(response.data.videoPath)
    })
  }, [id])

  return (
    <Layout containerMaxWidth="700">
      <Link target="_blank" to={`/${website.slug}`}>
        <Button
          size="small"
          style={{ marginBottom: '20px', marginLeft: 'auto' }}
        >
          Ver website
        </Button>
      </Link>

      <Panel title="Editar website" icon={<FiMonitor color="#9b59b6" />}>
        {formLoading ? (
          'Carregando formulário'
        ) : (
          <Formik
            initialValues={website}
            onSubmit={(values) => {
              setLoading(true)
              handleSubmit(values)
            }}
            enableReinitialize
          >
            {({ values, handleBlur, handleChange, setFieldValue }) => (
              <Form>
                {previewHero && (
                  <div style={{ marginBottom: '20px' }}>
                    <img src={previewHero} />
                  </div>
                )}

                <InputFile
                  name="card"
                  label="Imagem de capa"
                  onBlur={handleBlur}
                  onChange={(event) => {
                    if (event.target.files) {
                      const file = event.target.files[0]
                      setFieldValue('card', file)
                      setPreviewHero(URL.createObjectURL(file))
                    }
                  }}
                  style={{ marginBottom: '20px' }}
                />

                {previewPhoto && (
                  <div style={{ marginBottom: '20px' }}>
                    <img src={previewPhoto} />
                  </div>
                )}

                <InputFile
                  name="photo"
                  label="Foto"
                  onBlur={handleBlur}
                  onChange={(event) => {
                    if (event.target.files) {
                      const file = event.target.files[0]
                      setFieldValue('photo', file)
                      setPreviewPhoto(URL.createObjectURL(file))
                    }
                  }}
                  style={{ marginBottom: '20px' }}
                />

                {previewVideo && (
                  <div style={{ marginBottom: '20px' }}>
                    <video width="100%" controls>
                      <source src={previewVideo} />
                    </video>
                  </div>
                )}

                <InputFile
                  name="video"
                  label="Vídeo"
                  onBlur={handleBlur}
                  onChange={(event) => {
                    if (event.target.files) {
                      const file = event.target.files[0]
                      setFieldValue('video', file)
                      setPreviewVideo(URL.createObjectURL(file))
                    }
                  }}
                  style={{ marginBottom: '20px' }}
                />

                <Textarea
                  name="bio"
                  value={values.bio}
                  placeholder="Biografia"
                  onChange={handleChange}
                  style={{ marginBottom: '20px', whiteSpace: 'pre-line' }}
                />

                <Input
                  name="slug"
                  value={values.slug}
                  placeholder="URL do site"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                />

                <p style={{ marginBottom: '15px' }}>Redes sociais</p>

                <Input
                  name="facebook"
                  value={values.facebook}
                  placeholder="Link do perfil no Facebook"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                />

                <Input
                  name="instagram"
                  value={values.instagram}
                  placeholder="Link do perfil no Instagram"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                />

                <Input
                  name="youtube"
                  value={values.youtube}
                  placeholder="Link do canal no Youtube"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                />

                <Input
                  name="tiktok"
                  value={values.tiktok}
                  placeholder="Link do canal no Tiktok"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                />

                <Input
                  name="twitter"
                  value={values.twitter}
                  placeholder="Link do perfil no Twitter"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                />

                <Input
                  name="linkedin"
                  value={values.linkedin}
                  placeholder="Link do perfil no LinkedIn"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                />

                <Button type="submit" loading={loading}>
                  Enviar formulário
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Panel>
    </Layout>
  )
}

export default EditWebsite
