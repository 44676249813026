import { useToast } from '@chakra-ui/react'
import Button from 'components/Button'
import Input from 'components/Input'
import { Form, Formik } from 'formik'
import { useCallback, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import api from 'services/api'

interface FormRecoveryProps {
  email: string
}

const FormRecovery = () => {
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const { pathname } = useLocation()

  const handleSubmit = useCallback(
    async (values: FormRecoveryProps) => {
      try {
        await api.post('/users/recovery', values)

        toast({
          description:
            'Um link para a recuperação da sua senha foi enviado para o e-mail informado',
          status: 'success'
        })

        setLoading(false)
      } catch (error) {
        toast({
          description:
            'O e-mail de cadastro não foi identificado em nosso sistema',
          status: 'error'
        })
        setLoading(false)
      }
    },
    [toast]
  )

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={(values) => {
        setLoading(true)
        handleSubmit(values)
      }}
    >
      {({ values, handleChange, handleBlur }) => (
        <Form>
          <Input
            type="email"
            name="email"
            value={values.email}
            placeholder="E-mail de cadastro"
            style={{ marginBottom: '15px' }}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <Button type="submit" loading={loading} block>
            Enviar
          </Button>

          <Link
            to={pathname}
            style={{
              display: 'block',
              textAlign: 'center',
              marginTop: '10px'
            }}
          >
            &raquo; Voltar para o login
          </Link>
        </Form>
      )}
    </Formik>
  )
}

export default FormRecovery
