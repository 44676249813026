import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import Button from 'components/Button'
import InputFile from 'components/InputFile'
import Select from 'components/Select'
import api from 'services/api'
import { Form, Formik } from 'formik'
import { ProgressBar } from 'react-bootstrap'
import { useToast, Alert, AlertIcon } from '@chakra-ui/react'
import { VideoProps, UserProps, OfficeProps, ThemeProps } from 'App.d'
import BackButton from 'components/BackButton'

const EditVideo = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { id } = useParams()
  const [video, setVideo] = useState<VideoProps>({} as VideoProps)
  const [users, setUsers] = useState<UserProps[]>([])
  const [formLoading, setFormLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [preview, setPreview] = useState<string | undefined>(undefined)
  const [offices, setOffices] = useState<OfficeProps[]>([])
  const [themes, setThemes] = useState<ThemeProps[]>([])
  const videoRef = useRef<HTMLVideoElement | null>(null)

  const handleSubmit = useCallback(
    async (values: VideoProps) => {
      setLoading(true)
      try {
        console.log('values', values)
        const form = new FormData()

        if (values.video !== video.video) {
          form.append('video', values.video)
        }

        if (values.userId !== 'all' && values.userId !== '') {
          form.append('userId', values.userId)
        }

        form.append('title', values.title)

        if (values.themeId) {
          console.log('novo themeId', values.themeId)
          form.append('themeId', values.themeId)
        }

        if (values.category) {
          form.append('category', values.category)
        }

        const response = await api.put(`/videos/${id}`, form, {
          onUploadProgress: (data) => {
            if (values.video !== video.video) {
              setProgress(Math.round((100 * data.loaded) / data.total))
            }
          }
        })

        if (response.request.status === 200) {
          toast({
            description: 'O vídeo foi editado com sucesso',
            status: 'success'
          })
          setLoading(false)
          setProgress(0)
        }
      } catch (error) {
        console.log(error)
      }
    },
    [id, video, toast]
  )

  useEffect(() => {
    api.get('/users').then((response) => {
      setUsers(response.data.filter((user: UserProps) => user.type === 'user'))
    })
  }, [])

  useEffect(() => {
    api.get('/offices').then((response) => {
      setOffices(response.data)
    })
  }, [])

  useEffect(() => {
    api.get('/themes').then((response) => {
      setThemes(response.data)
    })
  }, [])

  useEffect(() => {
    setFormLoading(true)

    api.get(`/videos/${id}`).then((response) => {
      const video = response.data
      console.log('get video', video)
      setVideo(video)
      setPreview(video.videoPath)
      setFormLoading(false)
    })
  }, [id])

  return (
    <Layout containerMaxWidth="600">
      <a onClick={() => navigate(-1)}>
        <BackButton label="Voltar" style={{ marginBottom: '20px' }} />
      </a>

      <Panel title="Editar vídeo">
        {formLoading ? (
          'Carregando formulário'
        ) : (
          <Formik
            initialValues={video}
            onSubmit={(values) => handleSubmit(values)}
            enableReinitialize
          >
            {({ values, handleBlur, handleChange, setFieldValue }) => (
              <Form>
                <Select
                  name="userId"
                  placeholder="Candidato(a)"
                  defaultValue={values.userId ? values.userId : 'all'}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                >
                  <option value="all">Todos</option>
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.fullName}
                    </option>
                  ))}
                </Select>

                {(!values.userId || values.userId === 'all') && (
                  <>
                    <Select
                      name="category"
                      placeholder="Categoria"
                      defaultValue={values.category}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{ marginBottom: '20px' }}
                    >
                      <option value="">Escolha</option>
                      {offices.map((office) => (
                        <option key={office.id} value={office.id}>
                          {office.title}
                        </option>
                      ))}
                    </Select>

                    <Select
                      name="themeId"
                      placeholder="Tema"
                      defaultValue={values.themeId}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{ marginBottom: '20px' }}
                    >
                      <option value="">Escolha</option>
                      {themes.map((theme) => (
                        <option key={theme.id} value={theme.id}>
                          {theme.title}
                        </option>
                      ))}
                    </Select>
                  </>
                )}

                <Select
                  name="title"
                  placeholder="Tipo do vídeo"
                  defaultValue={values.title}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                >
                  <option value="">Escolha o tipo do vídeo</option>
                  <option value="Vídeo de 30 seg. para redes sociais">
                    Vídeo de 30 seg. para redes sociais
                  </option>
                  <option value="Vídeo de 1 min. para anúncios digitais">
                    Vídeo de 1 min. para anúncios digitais
                  </option>
                  <option value="Vídeo Story para Facebook, Instagram e Tiktok">
                    Vídeo Story para Facebook, Instagram e Tiktok
                  </option>
                </Select>

                <InputFile
                  name="video"
                  label="Vídeo"
                  onBlur={handleBlur}
                  onChange={(event) => {
                    if (event.target.files) {
                      const file = event.target.files[0]
                      setFieldValue('video', file)
                      setPreview(URL.createObjectURL(file))
                      videoRef.current?.load()
                    }
                  }}
                  style={{ marginBottom: '20px' }}
                />

                {preview && (
                  <video
                    width="100%"
                    controls
                    ref={videoRef}
                    style={{ marginBottom: '20px' }}
                  >
                    <source src={preview} />
                  </video>
                )}

                {progress > 0 && (
                  <div style={{ marginBottom: '20px' }}>
                    <Alert status="warning">
                      <AlertIcon />
                      Por favor, aguarde. O upload do vídeo está em andamento,
                      não feche esta tela até a conclusão.
                    </Alert>
                    <ProgressBar
                      style={{ borderRadius: '2px' }}
                      now={progress}
                      label={`${progress}%`}
                    />
                  </div>
                )}

                <Button type="submit" loading={loading}>
                  Enviar
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Panel>
    </Layout>
  )
}

export default EditVideo
