import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import InputFile from 'components/InputFile'
import Input from 'components/Input'
import { useCallback, useState } from 'react'
import { PartyProps } from 'App.d'
import api from 'services/api'
import { Form, Formik } from 'formik'
import { HexColorPicker, HexColorInput } from 'react-colorful'
import Button from 'components/Button'
import { useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import BackButton from 'components/BackButton'

const AddParty = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const [party] = useState<PartyProps>({} as PartyProps)
  const [loading, setLoading] = useState(false)
  const [color, setColor] = useState('#fff')
  const [logo, setLogo] = useState('')

  const handleSubmit = useCallback(
    async (values: PartyProps) => {
      try {
        const form = new FormData()

        form.append('name', values.name)
        form.append('initials', values.initials)

        if (values.themeColor) form.append('themeColor', values.themeColor)

        if (values.logo) {
          form.append('logo', values.logo)
        }

        await api.post('/parties/', form)

        toast({
          description: 'Partido cadastrado com sucesso',
          status: 'success'
        })

        setLoading(false)
        navigate('/parties')
      } catch (error) {
        console.log(error)
      }
    },
    [toast, navigate]
  )

  return (
    <Layout containerMaxWidth="600">
      <a onClick={() => navigate(-1)}>
        <BackButton label="Voltar" style={{ marginBottom: '20px' }} />
      </a>

      <Panel title="Cadastrar partido">
        <Formik
          initialValues={party}
          onSubmit={(values) => {
            setLoading(true)
            handleSubmit(values)
          }}
          enableReinitialize
        >
          {({ values, handleBlur, handleChange, setFieldValue }) => (
            <Form>
              <img
                src={logo}
                style={{ width: '200px', marginBottom: '20px' }}
              />

              <InputFile
                label=""
                name="logo"
                onChange={(event) => {
                  if (event.target.files) {
                    setLogo(URL.createObjectURL(event.target.files[0]))
                    setFieldValue('logo', event.target.files[0])
                  }
                }}
                onBlur={handleBlur}
                style={{ marginBottom: '20px' }}
              />

              <Input
                value={values.name}
                name="name"
                placeholder="Nome"
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ marginBottom: '20px' }}
              />

              <Input
                value={values.initials}
                name="initials"
                placeholder="Sigla"
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ marginBottom: '20px' }}
              />

              <div style={{ marginBottom: '20px' }}>
                <p>Cor padrão</p>
                <HexColorInput
                  color={values.themeColor || ''}
                  onChange={(color) => {
                    setFieldValue('themeColor', color)
                    setColor(color)
                  }}
                  style={{
                    marginBottom: '10px',
                    border: 'none',
                    background: `${color}`,
                    color: '#fff',
                    padding: '10px 20px',
                    fontSize: '1rem',
                    borderRadius: '2px'
                  }}
                />

                <HexColorPicker
                  color={values.themeColor || ''}
                  onChange={(color) => {
                    setFieldValue('themeColor', color)
                    setColor(color)
                  }}
                />
              </div>

              <Button type="submit" loading={loading}>
                Enviar formulário
              </Button>
            </Form>
          )}
        </Formik>
      </Panel>
    </Layout>
  )
}

export default AddParty
