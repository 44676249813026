import { useParams } from 'react-router-dom'
import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import Input from 'components/Input'
import Button from 'components/Button'
import { useCallback, useEffect, useState } from 'react'
import api from 'services/api'
import { FiUserCheck } from 'react-icons/fi'
import { Form, Formik } from 'formik'
import ibgeApi from 'services/ibge'
import { UserProps, PartyProps, OfficeProps } from 'App.d'
import { Alert, AlertIcon, Grid, GridItem, useToast } from '@chakra-ui/react'
import { loggedUserIsAdmin } from 'services/helpers'
import Select from 'components/Select'
import formValidation from './formValidation'

interface StateProps {
  id: number
  nome: string
  sigla: string
}

const EditUser = () => {
  const toast = useToast()
  const isAdmin = loggedUserIsAdmin()
  const { id } = useParams()
  const [formLoading, setFormLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState<UserProps>({} as UserProps)
  const [states, setStates] = useState<StateProps[]>([])
  const [parties, setParties] = useState<PartyProps[]>([])
  const [offices, setOffices] = useState<OfficeProps[]>([])

  const handleSubmit = useCallback(
    async (values: UserProps) => {
      setLoading(true)

      try {
        await api.put(`/users/${id}`, values)

        toast({
          description: 'Os dados foram salvos com sucesso',
          status: 'success'
        })

        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    },
    [id, toast]
  )

  useEffect(() => {
    setFormLoading(true)

    const getUser = api.get(`/users/${id}`)
    const getParties = api.get('/parties')
    const getOffices = api.get('/offices')
    const getStates = ibgeApi.get('/estados')

    Promise.all([getStates, getOffices, getParties, getUser]).then(
      (response) => {
        console.log(response[3].data)

        setStates(response[0].data)
        setOffices(response[1].data)
        setParties(response[2].data)
        setUser(response[3].data)

        setFormLoading(false)
      }
    )
  }, [id])

  return (
    <Layout containerMaxWidth="700">
      <Panel
        title={isAdmin ? 'Editar usuário' : 'Meus dados'}
        icon={<FiUserCheck color="1ABC9C" />}
      >
        {formLoading ? (
          'Carregando formulário'
        ) : (
          <Formik
            initialValues={user}
            onSubmit={handleSubmit}
            validationSchema={formValidation}
            enableReinitialize
          >
            {({ values, handleBlur, handleChange, errors, touched }) => (
              <Form>
                {isAdmin && (
                  <Select
                    name="type"
                    defaultValue={values.type}
                    placeholder="Nível de acesso"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                    validationError={
                      errors.type && touched.type ? errors.type : null
                    }
                  >
                    <option value="">Nível de acesso</option>
                    <option value="admin">Administrador</option>
                    <option value="user">Candidato(a)</option>
                  </Select>
                )}

                <Input
                  name="fullName"
                  placeholder="Nome"
                  value={values.fullName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                  validationError={
                    errors.fullName && touched.fullName ? errors.fullName : null
                  }
                />

                <Input
                  name="email"
                  placeholder="E-mail"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                />

                {user.type === 'user' && (
                  <Input
                    name="phone"
                    value={values.phone}
                    placeholder="Telefone celular"
                    mask="(99) 99999-9999"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                  />
                )}

                <Alert
                  status="warning"
                  variant="left-accent"
                  fontSize="sm"
                  style={{ marginBottom: '20px' }}
                >
                  <AlertIcon />
                  Caso não queira alterar a senha, apenas deixe os campos abaixo
                  em branco
                </Alert>

                <Grid templateColumns="repeat(2, 1fr)" gap={6}>
                  <GridItem>
                    <Input
                      type="password"
                      name="password"
                      placeholder="Senha"
                      value={''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{ marginBottom: '20px' }}
                    />
                  </GridItem>

                  <GridItem>
                    <Input
                      type="password"
                      name="password_conf"
                      placeholder="Confirme a senha"
                      value={''}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{ marginBottom: '20px' }}
                    />
                  </GridItem>
                </Grid>

                {loggedUserIsAdmin() && values.campaign && (
                  <>
                    <h5 style={{ marginBottom: '20px', fontWeight: '600' }}>
                      Dados eleitorais
                    </h5>

                    <Select
                      name="campaign.partyId"
                      defaultValue={
                        values.campaign ? values.campaign.partyId : ''
                      }
                      placeholder="Partido"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{ marginBottom: '20px' }}
                    >
                      <option value="">Escolha o partido</option>
                      {parties.map((party) => (
                        <option key={party.id} value={party.id}>
                          {`${party.name} (${party.initials})`}
                        </option>
                      ))}
                    </Select>

                    <Select
                      name="campaign.officeId"
                      defaultValue={
                        values.campaign ? values.campaign.officeId : ''
                      }
                      placeholder="Cargo em que irá concorrer"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{ marginBottom: '20px' }}
                    >
                      <option value="">Escolha o cargo</option>
                      {offices.map((office) => (
                        <option key={office.id} value={office.id}>
                          {office.title}
                        </option>
                      ))}
                    </Select>

                    <Input
                      name="campaign.electoralName"
                      placeholder="Nome de urna"
                      value={values.campaign && values.campaign.electoralName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{ marginBottom: '20px' }}
                    />

                    <Input
                      name="campaign.electoralNumber"
                      placeholder="Número de candidato"
                      value={values.campaign && values.campaign.electoralNumber}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{ marginBottom: '20px' }}
                    />

                    <Input
                      name="campaign.cnpj"
                      mask="99.999.999/9999-99"
                      placeholder="CNPJ da campanha"
                      value={values.campaign && values.campaign.cnpj}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{ marginBottom: '20px' }}
                    />

                    <Select
                      name="campaign.state"
                      defaultValue={values.campaign?.state || ''}
                      placeholder="Estado em que irá concorrer"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{ marginBottom: '20px' }}
                    >
                      <option value="">Escolha o estado</option>
                      {states.map((state) => (
                        <option key={state.id} value={state.sigla}>
                          {state.nome}
                        </option>
                      ))}
                    </Select>

                    <h5
                      style={{
                        marginBottom: '25px',
                        marginTop: '30px',
                        fontWeight: '600'
                      }}
                    >
                      Dados do assessor(a)
                    </h5>

                    <Input
                      name="campaign.accessorName"
                      placeholder="Nome do assessor(a)"
                      value={values.campaign.accessorName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{ marginBottom: '20px' }}
                    />

                    <Input
                      name="campaign.accessorEmail"
                      placeholder="E-mail do assessor(a)"
                      value={values.campaign.accessorEmail}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{ marginBottom: '20px' }}
                    />

                    <Input
                      name="campaign.accessorPhone"
                      placeholder="Telefone celular do assessor(a)"
                      value={values.campaign.accessorPhone}
                      mask="(99) 99999-9999"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{ marginBottom: '20px' }}
                    />
                  </>
                )}

                <Button type="submit" loading={loading}>
                  Salvar informações
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Panel>
    </Layout>
  )
}

export default EditUser
