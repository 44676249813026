import styled from 'styled-components'
import { breakpoints as sizes } from 'styles/breakpoints'

import { HeroProps } from '.'

export const Wrapper = styled.div<HeroProps>`
  width: 100%;
  height: 688px;
  background-color: ${(props) => props.defaultColor || '#333'};
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media (${sizes.lg}) {
    height: 500px !important;
  }

  @media (${sizes.md}) {
    height: 260px !important;
  }
`
