import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { FiEdit, FiTrash, FiUserCheck } from 'react-icons/fi'
import api from 'services/api'
import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import Table from 'components/Table'
import {
  Grid,
  GridItem,
  Select,
  Tag,
  Tooltip,
  useToast
} from '@chakra-ui/react'
import Button from 'components/Button'
import { UserProps } from 'App.d'

const Users = () => {
  const [users, setUsers] = useState<UserProps[]>([])
  const [filteredUsers, setFilteredUsers] = useState<UserProps[]>([])
  const toast = useToast()

  const handleDelete = useCallback(
    async (userId: string) => {
      try {
        if (confirm('Tem certeza que deseja deletar?')) {
          await api.delete(`/users/${userId}`)

          toast({
            description: 'Usuário deletado com sucesso',
            status: 'success'
          })

          setFilteredUsers(users.filter((user) => user.id !== userId))
        }
      } catch (error) {
        console.log(error)
      }
    },
    [toast, users]
  )

  useEffect(() => {
    api.get('/users').then((response) => {
      setUsers(response.data)
      setFilteredUsers(response.data)
    })
  }, [])

  return (
    <Layout>
      <Link to="/users/add">
        <Button
          size="small"
          style={{ marginBottom: '20px', marginLeft: 'auto' }}
        >
          Novo usuário
        </Button>
      </Link>

      <Panel title="Usuários" icon={<FiUserCheck color="#1ABC9C" />}>
        <Grid
          templateColumns="repeat(3, 1fr)"
          gap={6}
          style={{ marginBottom: '20px' }}
        >
          <GridItem>
            <Select
              placeholder="Filtrar"
              size="sm"
              colorScheme="green"
              onChange={(event) => {
                const value = event.currentTarget.value
                setFilteredUsers(users.filter((user) => user.type === value))
              }}
            >
              <option value="user">Candidatos</option>
              <option value="admin">Administradores</option>
            </Select>
          </GridItem>
        </Grid>

        <Table>
          <thead>
            <tr>
              <th>Usuário</th>
              <th>Nome de urna</th>
              <th>Partido</th>
              <th>E-mail</th>
              <th>Nível</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.id}>
                <td>{user.fullName}</td>
                <td>{user.campaign?.electoralName}</td>
                <td>{user.campaign?.party?.initials}</td>
                <td>{user.email}</td>
                <td>
                  {user.type === 'user' ? (
                    <Tag colorScheme="purple">Candidato(a)</Tag>
                  ) : (
                    <Tag colorScheme="blue">Administrador</Tag>
                  )}
                </td>
                <td style={{ width: '80px' }}>
                  <div style={{ display: 'flex' }}>
                    <Tooltip label="Editar" placement="top" hasArrow>
                      <Link to={`/users/${user.id}`}>
                        <FiEdit size={20} color="#3498DB" />
                      </Link>
                    </Tooltip>

                    <Tooltip label="Deletar" placement="top" hasArrow>
                      <Link to="#" style={{ marginLeft: '6px' }}>
                        <FiTrash
                          size={20}
                          color="#E74C3C"
                          onClick={() => handleDelete(user.id)}
                        />
                      </Link>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Panel>
    </Layout>
  )
}

export default Users
