import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import Table from 'components/Table'
import { useCallback, useEffect, useState } from 'react'
import { FlagProps } from 'App.d'
import api from 'services/api'
import { Tooltip, useToast } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiEdit, FiTrash } from 'react-icons/fi'
import Button from 'components/Button'

const Flags = () => {
  const toast = useToast()
  const [flags, setFlags] = useState<FlagProps[]>([])

  const handleDelete = useCallback(
    async (flagId: string) => {
      try {
        if (confirm('Tem certeza que deseja deletar?')) {
          await api.delete(`/flags/${flagId}`)

          toast({
            description: 'Bandeira deletada com sucesso',
            status: 'success'
          })

          setFlags(flags.filter((flag) => flag.id !== flagId))
        }
      } catch (error) {
        console.log(error)
      }
    },
    [toast, flags]
  )

  useEffect(() => {
    api.get('/flags').then((response) => {
      setFlags(response.data)
    })
  }, [])

  return (
    <Layout>
      <Link to="/flags/add">
        <Button
          size="small"
          style={{ marginBottom: '20px', marginLeft: 'auto' }}
        >
          Nova bandeira
        </Button>
      </Link>

      <Panel title="Bandeiras">
        <Table>
          <thead>
            <tr>
              <th></th>
              <th>Título</th>
              <th>Descrição</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {flags.map((flag) => (
              <tr key={flag.id}>
                <td style={{ width: '110px' }}>
                  <img src={flag.imagePath} width="100" />
                </td>
                <td>{flag.title}</td>
                <td>{flag.description}</td>
                <td style={{ width: '80px' }}>
                  <div style={{ display: 'flex' }}>
                    <Tooltip label="Editar" placement="top" hasArrow>
                      <Link to={`/flags/${flag.id}`}>
                        <FiEdit size={20} color="#3498DB" />
                      </Link>
                    </Tooltip>

                    <Tooltip label="Deletar" placement="top" hasArrow>
                      <Link to="#" style={{ marginLeft: '6px' }}>
                        <FiTrash
                          size={20}
                          color="#E74C3C"
                          onClick={() => flag.id && handleDelete(flag.id)}
                        />
                      </Link>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Panel>
    </Layout>
  )
}

export default Flags
