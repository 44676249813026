import { useEffect, useState } from 'react'
import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import Notification from 'components/Notification'
import { useAuth } from 'context/AuthContext'
import api from 'services/api'
import { NotificationProps } from 'App.d'
import { getNotificationTitle, loggedUserIsAdmin } from 'services/helpers'
import moment from 'moment'
import Statistics from './Statistics'

const Dashboard = () => {
  const { user } = useAuth()
  const isAdmin = loggedUserIsAdmin()
  const [notifications, setNotification] = useState<NotificationProps[]>([])

  useEffect(() => {
    api.get(`/notifications/?userId=${user.id}`).then((response) => {
      setNotification(response.data)
    })
  }, [user.id])

  return (
    <Layout>
      {notifications.length > 0 && (
        <Panel title="Notificações">
          {notifications.map((notification) => (
            <Notification
              key={notification.id}
              type={notification.type}
              date={moment(notification.createdAt).locale('pt-br').fromNow()}
              title={getNotificationTitle(notification)}
            />
          ))}
        </Panel>
      )}

      {!isAdmin && <Statistics />}
    </Layout>
  )
}

export default Dashboard
