import React, {
  useState,
  useEffect,
  useRef,
  TextareaHTMLAttributes
} from 'react'

import { Wrapper, InputWrapper, InputPlaceholder, HelpText } from './styles'

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  validationError?: string | null | undefined
}

const Textarea: React.FC<TextareaProps> = ({
  placeholder,
  value,
  style,
  validationError = null,
  onChange,
  ...rest
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null)

  const [focused, setFocused] = useState(false)

  function handleClick() {
    setFocused(true)
    if (inputRef.current !== null) {
      inputRef.current.focus()
    }
  }

  function handleBlur() {
    console.log('blur')
    if (inputRef.current && !inputRef.current.value) {
      setFocused(false)
    }
  }

  useEffect(() => {
    if (value) {
      setFocused(true)
    }
  }, [value])

  return (
    <Wrapper style={style}>
      <InputWrapper focused={focused}>
        <textarea
          ref={inputRef}
          className={validationError ? 'danger' : ''}
          defaultValue={value || ''}
          onChange={onChange}
          onClick={handleClick}
          onBlur={handleBlur}
          onFocus={() => setFocused(true)}
          {...rest}
        />
        <InputPlaceholder>{placeholder}</InputPlaceholder>
      </InputWrapper>

      {validationError && <HelpText>{validationError}</HelpText>}
    </Wrapper>
  )
}

export default Textarea
