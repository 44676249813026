import { useToast } from '@chakra-ui/react'
import Button from 'components/Button'
import Input from 'components/Input'
import { Form, Formik } from 'formik'
import { useCallback, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import api from 'services/api'

interface FormPasswordProps {
  password: string
  password_conf: string
  token: string | null
}

const FormPassword = () => {
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const toast = useToast()
  const navigate = useNavigate()

  const handleSubmit = useCallback(
    async (values: FormPasswordProps) => {
      try {
        await api.patch('/users', values)

        toast({
          description: 'Sua senha foi alterada com sucesso!',
          status: 'success'
        })

        setLoading(false)
        navigate('/login')
      } catch (error) {
        toast({
          description: 'Ocorreu um erro ao concluir a operação',
          status: 'error'
        })
        setLoading(false)
      }
    },
    [toast, navigate]
  )

  return (
    <Formik
      initialValues={{
        password: '',
        password_conf: '',
        token: searchParams.get('token')
      }}
      onSubmit={(values) => {
        setLoading(true)
        handleSubmit(values)
      }}
    >
      {({ values, handleChange, handleBlur }) => (
        <Form>
          <Input
            type="password"
            name="password"
            value={values.password}
            placeholder="Nova senha"
            style={{ marginBottom: '15px' }}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <Input
            type="password"
            name="password_conf"
            value={values.password_conf}
            placeholder="Repita a nova senha"
            style={{ marginBottom: '15px' }}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <Button type="submit" loading={loading} block>
            Enviar
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default FormPassword
