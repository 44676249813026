import { useCallback, useEffect, useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import { FiHeadphones } from 'react-icons/fi'
import Button from 'components/Button'
import InputFile from 'components/InputFile'
import Panel from 'components/Panel'
import Select from 'components/Select'
import { ProgressBar } from 'react-bootstrap'
import Layout from 'templates/Layout'
import api from 'services/api'
import { Alert, AlertIcon, useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import BackButton from 'components/BackButton'
import { UserProps, AudioProps, OfficeProps } from 'App.d'

const EditAudio = () => {
  const { id } = useParams()
  const toast = useToast()
  const navigate = useNavigate()
  const [formLoading, setFormLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [audio, setAudio] = useState<AudioProps>({} as AudioProps)
  const [users, setUsers] = useState<UserProps[]>([])
  const [progress, setProgress] = useState(0)
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const [preview, setPreview] = useState<string | undefined>()
  const [offices, setOffices] = useState<OfficeProps[]>([])

  const handleSubmit = useCallback(
    async (values: AudioProps) => {
      try {
        const form = new FormData()

        if (values.userId !== 'all' && values.userId !== '') {
          form.append('userId', values.userId)
        }

        form.append('title', values.title)

        if (values.audio !== audio.audio) {
          form.append('audio', values.audio)
        }

        if (values.category) {
          form.append('category', values.category)
        }

        const response = await api.put(`/audios/${id}`, form, {
          onUploadProgress: (data) => {
            if (values.audio !== audio.audio) {
              setProgress(Math.round((100 * data.loaded) / data.total))
            }
          }
        })

        if (response.request.status === 200) {
          toast({
            description: 'O áudio foi editado com sucesso',
            status: 'success'
          })
          setLoading(false)
          setProgress(0)
        }
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    },
    [id, audio, toast]
  )

  useEffect(() => {
    api.get('/users').then((response) => {
      setUsers(response.data.filter((user: UserProps) => user.type === 'user'))
    })
  }, [])

  useEffect(() => {
    api.get('/offices').then((response) => {
      setOffices(response.data)
    })
  }, [])

  useEffect(() => {
    setFormLoading(true)

    api.get(`/audios/${id}`).then((response) => {
      const audio = response.data
      setAudio(audio)
      setPreview(audio.audioPath)
      setFormLoading(false)
    })
  }, [id])

  return (
    <Layout containerMaxWidth="600">
      <a onClick={() => navigate(-1)}>
        <BackButton label="Voltar" style={{ marginBottom: '20px' }} />
      </a>

      <Panel title="Enviar novo áudio" icon={<FiHeadphones color="#1abc9c" />}>
        {formLoading ? (
          'Carregando formulário'
        ) : (
          <Formik
            initialValues={audio}
            onSubmit={(values) => {
              setLoading(true)
              handleSubmit(values)
            }}
            enableReinitialize
          >
            {({ values, handleBlur, handleChange, setFieldValue }) => (
              <Form>
                <Select
                  name="userId"
                  placeholder="Candidato(a)"
                  defaultValue={values.userId ? values.userId : 'all'}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                >
                  <option value="all">Todos</option>
                  {users.map((user) => (
                    <option
                      key={user.id}
                      value={user.id}
                    >{`${user.fullName} (${user.campaign?.electoralName})`}</option>
                  ))}
                </Select>

                {(!values.userId || values.userId === 'all') && (
                  <Select
                    name="category"
                    placeholder="Categoria"
                    defaultValue={values.category}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                  >
                    <option value="">Escolha</option>
                    {offices.map((office) => (
                      <option key={office.id} value={office.id}>
                        {office.title}
                      </option>
                    ))}
                  </Select>
                )}

                <Select
                  name="title"
                  placeholder="Tipo do Áudio"
                  defaultValue={values.title}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                >
                  <option value="">Escolha o tipo do áudio</option>
                  <option value="Podcast">Podcast</option>
                  <option value="Jingle">Jingle</option>
                  <option value="Convite">Convite</option>
                </Select>

                <InputFile
                  name="audio"
                  label="Áudio"
                  onBlur={handleBlur}
                  onChange={(event) => {
                    if (event.target.files) {
                      const file = event.target.files[0]
                      setFieldValue('audio', file)
                      setPreview(URL.createObjectURL(file))
                      audioRef.current?.load()
                    }
                  }}
                  style={{ marginBottom: '20px' }}
                />

                {preview && (
                  <audio
                    controls
                    ref={audioRef}
                    style={{ marginBottom: '20px', width: '100%' }}
                  >
                    <source src={preview} />
                  </audio>
                )}

                {progress > 0 && (
                  <div style={{ marginBottom: '20px' }}>
                    <Alert
                      status="warning"
                      fontSize="sm"
                      style={{ marginBottom: '10px' }}
                    >
                      <AlertIcon />
                      Por favor, aguarde. O upload está em andamento, não feche
                      esta tela até a conclusão.
                    </Alert>
                    <ProgressBar
                      style={{ borderRadius: '2px' }}
                      now={progress}
                      label={`${progress}%`}
                    />
                  </div>
                )}

                <Button type="submit" loading={loading}>
                  Enviar
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Panel>
    </Layout>
  )
}

export default EditAudio
