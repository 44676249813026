import Router from 'routes'
import { AuthProvider } from 'context/AuthContext'
import { ChakraProvider } from '@chakra-ui/react'
import { CampaignInfoProvider } from 'context/CampaignInfoContext'
import GlobalStyles from 'styles/global'

import { MyThemeProvider } from 'context/ThemeUpdateContext'

import 'moment/locale/pt-br'

function App() {
  return (
    <MyThemeProvider>
      <AuthProvider>
        <CampaignInfoProvider>
          <ChakraProvider>
            <Router />
          </ChakraProvider>
        </CampaignInfoProvider>
      </AuthProvider>
      <GlobalStyles />
    </MyThemeProvider>
  )
}

export default App
