import { useCallback, useEffect, useRef, useState } from 'react'
import { Form, Formik } from 'formik'
import { FiVideo } from 'react-icons/fi'
import Panel from 'components/Panel'
import Button from 'components/Button'
import InputFile from 'components/InputFile'
import Select from 'components/Select'
import { ProgressBar } from 'react-bootstrap'
import Layout from 'templates/Layout'
import api from 'services/api'
import { Alert, AlertIcon, useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import BackButton from 'components/BackButton'
import {
  UserProps,
  VideoProps,
  OfficeProps,
  PartyProps,
  ThemeProps
} from 'App.d'

const AddVideo = () => {
  const { id } = useParams()
  const toast = useToast()
  const navigate = useNavigate()
  const [formLoading, setFormLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data] = useState<VideoProps>({
    userId: id ? id : 'all',
    title: '',
    video: '',
    category: ''
  })
  const [users, setUsers] = useState<UserProps[]>([])
  const [progress, setProgress] = useState(0)
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const [preview, setPreview] = useState<string | undefined>()
  const [offices, setOffices] = useState<OfficeProps[]>([])
  const [parties, setParties] = useState<PartyProps[]>([])
  const [themes, setThemes] = useState<ThemeProps[]>([])

  const handleSubmit = useCallback(
    async (values: VideoProps) => {
      try {
        const form = new FormData()

        if (values.userId !== 'all' && values.userId !== '') {
          form.append('userId', values.userId)
        }

        form.append('title', values.title)
        form.append('video', values.video)

        values.partyId && form.append('partyId', values.partyId)
        values.themeId && form.append('themeId', values.themeId)

        if (values.category) {
          form.append('category', values.category)
        }

        const response = await api.post('/videos', form, {
          onUploadProgress: (data) => {
            setProgress(Math.round((100 * data.loaded) / data.total))
          }
        })

        if (response.request.status === 200) {
          toast({
            description: 'O vídeo foi cadastrado com sucesso',
            status: 'success'
          })
          setLoading(false)

          if (values.userId === 'all') {
            navigate(`/videos/shared`)
          } else {
            navigate(`/videos/user/${values.userId}`)
          }
        }
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    },
    [toast, navigate]
  )

  useEffect(() => {
    setFormLoading(true)

    api.get(`/users`).then((response) => {
      setUsers(response.data.filter((user: UserProps) => user.type === 'user'))
      setFormLoading(false)
    })
  }, [id])

  useEffect(() => {
    setFormLoading(true)

    api.get('/offices').then((response) => {
      setOffices(response.data)
      setFormLoading(false)
    })
  }, [])

  useEffect(() => {
    api.get('/parties').then((response) => {
      setParties(response.data)
    })
  }, [])

  useEffect(() => {
    api.get('/themes').then((response) => {
      setThemes(response.data)
    })
  }, [])

  return (
    <Layout containerMaxWidth="600">
      <a onClick={() => navigate(-1)}>
        <BackButton label="Voltar" style={{ marginBottom: '20px' }} />
      </a>

      <Panel title="Enviar novo vídeo" icon={<FiVideo color="#9B59B6" />}>
        {formLoading ? (
          'Carregando formulário'
        ) : (
          <Formik
            initialValues={data}
            onSubmit={(values) => {
              setLoading(true)
              handleSubmit(values)
            }}
            enableReinitialize
          >
            {({ values, handleBlur, handleChange, setFieldValue }) => (
              <Form>
                <Select
                  name="userId"
                  placeholder="Candidato(a)"
                  defaultValue={id ? id : values.userId}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                >
                  <option value="all">Todos</option>
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.fullName} ({user.campaign?.electoralName})
                    </option>
                  ))}
                </Select>

                {values.userId === 'all' && (
                  <>
                    <Select
                      name="partyId"
                      placeholder="Partido"
                      defaultValue={values.partyId}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{ marginBottom: '20px' }}
                    >
                      <option value="">Escolha</option>
                      {parties.map((party) => (
                        <option key={party.id} value={party.id}>
                          {party.name} ({party.initials})
                        </option>
                      ))}
                    </Select>

                    <Select
                      name="category"
                      placeholder="Categoria"
                      defaultValue={values.category}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{ marginBottom: '20px' }}
                    >
                      <option value="">Escolha</option>
                      {offices.map((office) => (
                        <option key={office.id} value={office.id}>
                          {office.title}
                        </option>
                      ))}
                    </Select>

                    <Select
                      name="themeId"
                      placeholder="Tema"
                      defaultValue={values.themeId}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{ marginBottom: '20px' }}
                    >
                      <option value="">Escolha</option>
                      {themes.map((theme) => (
                        <option key={theme.id} value={theme.id}>
                          {theme.title}
                        </option>
                      ))}
                    </Select>
                  </>
                )}

                <Select
                  name="title"
                  placeholder="Tipo do vídeo"
                  defaultValue={values.title}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                >
                  <option value="">Escolha o tipo do vídeo</option>
                  <option value="Vídeo de 1 min. para Redes sociais">
                    Vídeo de 1 min. para Redes sociais
                  </option>
                </Select>

                <InputFile
                  name="video"
                  label="Vídeo"
                  onBlur={handleBlur}
                  onChange={(event) => {
                    if (event.target.files) {
                      const file = event.target.files[0]
                      setFieldValue('video', file)
                      setPreview(URL.createObjectURL(file))
                      videoRef.current?.load()
                    }
                  }}
                  style={{ marginBottom: '20px' }}
                />

                {preview && (
                  <video
                    width="100%"
                    controls
                    ref={videoRef}
                    style={{ marginBottom: '20px' }}
                  >
                    <source src={preview} />
                  </video>
                )}

                {loading && (
                  <div style={{ marginBottom: '20px' }}>
                    <Alert
                      status="warning"
                      fontSize="sm"
                      style={{ marginBottom: '10px' }}
                    >
                      <AlertIcon />
                      Por favor, aguarde. O upload do vídeo está em andamento,
                      não feche esta tela até a conclusão.
                    </Alert>
                    <ProgressBar
                      style={{ borderRadius: '2px' }}
                      now={progress}
                      label={`${progress}%`}
                    />
                  </div>
                )}

                <Button type="submit" loading={loading}>
                  Enviar
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Panel>
    </Layout>
  )
}

export default AddVideo
