import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import { useNavigate, useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { UserProps } from 'App.d'
import api from 'services/api'
import { Form, Formik } from 'formik'
import Select from 'components/Select'
import UploadZone from 'components/UploadZone'
import Button from 'components/Button'
import { useToast } from '@chakra-ui/react'
import { FiCamera } from 'react-icons/fi'
import { PhotoProps } from 'App.d'

const AddPhoto = () => {
  const { id } = useParams()
  const toast = useToast()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState<UserProps>({} as UserProps)

  const data: PhotoProps = {
    userId: id ? id : '',
    title: 'Foto de campanha',
    photo: []
  }

  const handleSubmit = useCallback(
    async (values: PhotoProps) => {
      try {
        const form = new FormData()

        form.append('userId', values.userId)
        form.append('title', values.title)
        form.append('category', 'Estadual')

        values.photo.map((photo) => {
          form.append('photo', photo)
        })

        const response = await api.post('/photos', form)

        if (response.request.status === 200) {
          toast({
            description: 'Fotos cadastradas com sucesso',
            status: 'success'
          })
          setLoading(false)
          navigate(`/photos/user/${values.userId}`)
        }
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    },
    [navigate, toast]
  )

  useEffect(() => {
    api.get(`/users/${id}`).then((response) => {
      setUser(response.data)
    })
  }, [id])
  return (
    <Layout containerMaxWidth="600">
      <Panel
        title={`Adicionar fotos para o usuário ${user.fullName}`}
        icon={<FiCamera color="#f39c12" />}
      >
        <Formik
          initialValues={data}
          onSubmit={(values) => {
            setLoading(true)
            handleSubmit(values)
            console.log(values)
          }}
        >
          {({ values, handleBlur, handleChange, setFieldValue }) => (
            <Form>
              <Select
                name="userId"
                placeholder="Candidato(a)"
                defaultValue={id ? id : values.userId}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{ marginBottom: '20px' }}
              >
                <option
                  value={user.id}
                >{`${user.fullName} (${user.campaign?.electoralName})`}</option>
              </Select>

              <UploadZone
                multiple
                onDrop={(files) => setFieldValue('photo', files)}
                style={{ marginBottom: '20px' }}
              />

              <Button type="submit" loading={loading}>
                Enviar
              </Button>
            </Form>
          )}
        </Formik>
      </Panel>
    </Layout>
  )
}

export default AddPhoto
