import styled from 'styled-components'

export const Wrapper = styled.main`
  display: flex;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--gray-200);
  cursor: pointer;

  &:hover {
    > div {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;

  > svg {
    width: 23px;
    height: 23px;
  }
`

export const Content = styled.div`
  flex: 1;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.3rem;
  color: var(--gray-900);
  transition: color 200ms ease;

  span {
    display: block;
    margin-bottom: 5px;
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: var(--gray-500);
  }
`

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;

  > svg {
    width: 23px;
    height: 23px;
    color: ${(props) => props.theme.colors.primary};
  }
`
