import React, { createContext, useCallback, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from 'services/api'
import { UserProps } from 'App.d'
import { useToast } from '@chakra-ui/react'
// import { ThemeUpdateContext } from './ThemeUpdateContext'

interface SignInCredentials {
  email: string
  password: string
}

interface AuthContextProps {
  user: UserProps
  signIn(credentials: SignInCredentials): Promise<void>
  signOut(): void
  updateUser(): Promise<void>
  loading: boolean
}

interface AuthProps {
  user: UserProps
  token: string
}

interface AuthProviderProps {
  children: React.ReactNode
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps)

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate()
  const toast = useToast()
  // const updateTheme = useContext(ThemeUpdateContext)
  const [loading, setLoading] = useState(false)

  const [data, setData] = useState<AuthProps>(() => {
    const user = localStorage.getItem('@MinhaCandidatura:user')
    const token = localStorage.getItem('@MinhaCandidatura:token')

    if (token && user) {
      return { token, user: JSON.parse(user) }
    }

    return {} as AuthProps
  })

  const signIn = useCallback(
    async ({ email, password }: SignInCredentials) => {
      setLoading(true)

      try {
        const response = await api.post('/users/auth', { email, password })
        const { user, token } = response.data

        localStorage.setItem('@MinhaCandidatura:user', JSON.stringify(user))
        localStorage.setItem('@MinhaCandidatura:token', token)

        // if (user.type === 'user') {
        //   updateTheme(user.campaign.party.themeColor)
        // }

        setData({ user, token })
        setLoading(false)
        navigate('/', { replace: true })
      } catch (error) {
        toast({
          description:
            'Ocorreu um erro ao fazer o login. Por favor, Verifique os dados e tente novamente.',
          status: 'error'
        })
        setLoading(false)
      }
    },
    [navigate, toast]
  )

  const signOut = useCallback(() => {
    const userStorage = localStorage.getItem('@MinhaCandidatura:user')
    const user: UserProps = userStorage ? JSON.parse(userStorage) : null

    localStorage.removeItem('@MinhaCandidatura:user')
    localStorage.removeItem('@MinhaCandidatura:token')

    setData({} as AuthProps)
    user.type === 'user'
      ? navigate(`/login/${user.campaign?.party?.initials.toLowerCase()}`)
      : navigate('/login')
  }, [navigate])

  const updateUser = useCallback(async () => {
    const response = await api.get(`/users/${data.user.id}`)

    localStorage.setItem(
      '@MinhaCandidatura:user',
      JSON.stringify(response.data)
    )

    setData({ token: data.token, user: response.data })
  }, [data])

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser, loading }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used whitin an AuthProvider')
  }

  return context
}
