import * as S from './styles'

export interface HeroProps {
  defaultColor?: string | null | undefined
  backgroundImage?: string | null
}

const Hero = ({ defaultColor, backgroundImage }: HeroProps) => {
  return (
    <S.Wrapper
      defaultColor={defaultColor}
      backgroundImage={backgroundImage}
    ></S.Wrapper>
  )
}

export default Hero
