import styled, { css } from 'styled-components'

interface SelectWrapperProps {
  focused: boolean
}

export const Wrapper = styled.div`
  position: relative;
  &.danger {
    select {
      border-color: ${(props) => props.theme.colors.danger} !important;
    }
  }
`

export const WrapperInner = styled.div<SelectWrapperProps>`
  position: relative;
  display: flex;
  height: 54px;
  z-index: 1;
  select {
    appearance: none;
    display: flex;
    flex: 1;
    padding: 0 20px;
    border: 2px solid var(--gray-500);
    border-radius: 2px;
    font-size: 1rem;
    color: var(--gray-700);
    background: transparent;
    cursor: pointer;
    z-index: 200;
    ${(props) =>
      props.focused &&
      css`
        border-color: #54b492;
      `}
  }
`

export const SelectPlaceholder = styled.span`
  position: absolute;
  top: 0;
  left: 10px;
  margin-top: -9px;
  padding: 0 10px;
  font-size: 0.875rem;
  background: #fff;
  line-height: 1.125rem;
  color: var(--gray-700);
  transition: all 200ms ease;
  z-index: 100;
`

export const SelectArrowWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 50px;
  background: var(--gray-200);
`

export const HelpText = styled.p`
  display: block;
  margin: 5px 0 0 0;
  padding: 0;
  font-size: 0.875rem;
  color: var(--gray-600);
`
