import * as Yup from 'yup'
import ValidationErrorsMessages from 'utils/validationErrorsMessages'

const formValidation = Yup.object().shape({
  partyId: Yup.string().required(ValidationErrorsMessages.required),
  officeId: Yup.string().required(ValidationErrorsMessages.required),
  electoralName: Yup.string()
    .required(ValidationErrorsMessages.required)
    .nullable(),
  electoralNumber: Yup.string()
    .required(ValidationErrorsMessages.required)
    .nullable(),
  cnpj: Yup.string().required(ValidationErrorsMessages.required).nullable(),
  state: Yup.string().required(ValidationErrorsMessages.required).nullable()
})

export default formValidation
