const defaultTheme = {
  title: 'default',
  colors: {
    primary: '#54B492',
    secondary: '#B3C352',
    success: '#54B492',
    warning: '#E3AA2A',
    danger: '#e74c3c',
    verticalGradient:
      'linear-gradient(180deg, rgba(179, 195, 82, 1) 0%, rgba(80, 172, 143, 1) 100%)',
    horizontalGradient:
      'linear-gradient(90deg, rgba(179, 195, 82, 1) 0%, rgba(80, 172, 143, 1) 100%)'
  }
}

export default defaultTheme
