import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import InputFile from 'components/InputFile'
import { FiImage } from 'react-icons/fi'
import { GraphicsMaterialProps, UserProps } from 'App.d'
import { useNavigate, useParams } from 'react-router-dom'
import { Alert, AlertIcon, useToast } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import api from 'services/api'
import Button from 'components/Button'
import { Form, Formik } from 'formik'
import { ProgressBar } from 'react-bootstrap'
import Select from 'components/Select'

const EditGraphicsMaterial = () => {
  const { id } = useParams()
  const toast = useToast()
  const navigate = useNavigate()
  const [users, setUsers] = useState<UserProps[]>([])
  const [material, setMaterial] = useState<GraphicsMaterialProps>(
    {} as GraphicsMaterialProps
  )
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(false)
  const [formLoading, setFormLoading] = useState(false)

  const handleSubmit = useCallback(
    async (values: GraphicsMaterialProps) => {
      try {
        const form = new FormData()

        if (values.material !== material.material) {
          form.append('material', values.material)
        }

        form.append('userId', values.userId)
        form.append('title', values.title)

        const response = await api.put(`/material-graphics/${id}`, form, {
          onUploadProgress: (data) => {
            if (values.material !== material.material) {
              setProgress(Math.round((100 * data.loaded) / data.total))
            }
          }
        })

        if (response.request.status === 200) {
          toast({
            description: 'O material foi cadastrado com sucesso',
            status: 'success'
          })
          setLoading(false)
          navigate(`/graphics-material/user/${values.userId}`)
        }
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    },
    [toast, navigate, id, material.material]
  )

  useEffect(() => {
    setFormLoading(true)

    api.get(`/material-graphics/${id}`).then((response) => {
      setMaterial(response.data)
      setFormLoading(false)
    })
  }, [id])

  useEffect(() => {
    api.get('/users').then((response) => {
      setUsers(response.data)
    })
  }, [])

  return (
    <Layout containerMaxWidth="600">
      <Panel title="Editar material gráfico" icon={<FiImage color="#3498DB" />}>
        {formLoading ? (
          'Carregando formulário'
        ) : (
          <Formik
            initialValues={material}
            onSubmit={(values) => {
              setLoading(true)
              handleSubmit(values)
            }}
            enableReinitialize
          >
            {({ values, handleBlur, handleChange, setFieldValue }) => (
              <Form>
                <Select
                  name="userId"
                  placeholder="Candidato(a)"
                  defaultValue={values.userId}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                >
                  {users.map((user) => (
                    <option
                      key={user.id}
                      value={user.id}
                    >{`${user.fullName} (${user.campaign?.electoralName})`}</option>
                  ))}
                </Select>

                <Select
                  name="title"
                  placeholder="Tipo do material"
                  defaultValue={values.title}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                >
                  <option value="">Escolha o tipo de material</option>
                  <option value="Arte para Santinho">Arte para Santinho</option>
                  <option value="Arte para Praguinha">
                    Arte para Praguinha
                  </option>
                  <option value="Arte para Bandeira">Arte para Bandeira</option>
                  <option value="Arte para Camiseta">Arte para Camiseta</option>
                </Select>

                <InputFile
                  name="material"
                  label="Material"
                  onBlur={handleBlur}
                  onChange={(event) => {
                    if (event.target.files) {
                      const file = event.target.files[0]
                      setFieldValue('material', file)
                    }
                  }}
                  style={{ marginBottom: '20px' }}
                />

                <div style={{ marginBottom: '20px' }}>
                  <p style={{ marginBottom: '10px' }}>
                    <b>Arquivo:</b> {material.material}
                  </p>
                  <a
                    href={material.materialPath}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Visualizar arquivo atual
                  </a>
                </div>

                {progress > 0 && (
                  <div style={{ marginBottom: '20px' }}>
                    <Alert
                      status="warning"
                      fontSize="sm"
                      style={{ marginBottom: '10px' }}
                    >
                      <AlertIcon />
                      Por favor, aguarde. O upload está em andamento, não feche
                      esta tela até a conclusão.
                    </Alert>
                    <ProgressBar
                      style={{ borderRadius: '2px' }}
                      now={progress}
                      label={`${progress}%`}
                    />
                  </div>
                )}

                <Button type="submit" loading={loading}>
                  Enviar
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Panel>
    </Layout>
  )
}

export default EditGraphicsMaterial
