import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import { FiEye, FiMonitor } from 'react-icons/fi'
import Table from 'components/Table'
import { ChangeEvent, useEffect, useState } from 'react'
import { UserProps } from 'App.d'
import api from 'services/api'
import { Grid, GridItem, Input, Tooltip } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const Websites = () => {
  const [users, setUsers] = useState<UserProps[]>([])
  const [filteredUsers, setFilteredUsers] = useState<UserProps[]>([])

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase()

    setFilteredUsers(
      users.filter((user) => {
        if (value === '') return users

        return (
          user.fullName.toLowerCase().includes(value) ||
          user.campaign?.electoralName.toLowerCase().includes(value) ||
          user.campaign?.party?.initials.toLowerCase().includes(value)
        )
      })
    )
  }

  useEffect(() => {
    api.get('/users').then((response) => {
      setUsers(response.data.filter((user: UserProps) => user.type === 'user'))
      setFilteredUsers(
        response.data.filter((user: UserProps) => user.type === 'user')
      )
    })
  }, [])

  return (
    <Layout>
      <Panel title="Websites" icon={<FiMonitor color="#9b59b6" />}>
        <Grid
          templateColumns="repeat(3, 1fr)"
          gap={6}
          style={{ marginBottom: '20px' }}
        >
          <GridItem>
            <Input placeholder="Buscar" size="sm" onChange={handleSearch} />
          </GridItem>
        </Grid>

        <Table>
          <thead>
            <tr>
              <th>Usuário</th>
              <th>Nome de urna</th>
              <th>Partido</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.id}>
                <td>{user.fullName}</td>
                <td>{user.campaign?.electoralName}</td>
                <td>{user.campaign?.party?.initials}</td>
                <td style={{ width: '70px' }}>
                  <div style={{ display: 'flex' }}>
                    <Tooltip hasArrow placement="top" label="Gerenciar website">
                      <Link
                        to={`/websites/${user.site?.id}`}
                        style={{ marginRight: '10px' }}
                      >
                        <FiMonitor size={20} color="#9b59b6" />
                      </Link>
                    </Tooltip>

                    <Tooltip hasArrow placement="top" label="Ver website">
                      <Link to={`/${user.site?.slug}`} target="_blank">
                        <FiEye size={20} color="#3498DB" />
                      </Link>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Panel>
    </Layout>
  )
}

export default Websites
