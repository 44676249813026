import styled, { css } from 'styled-components'
import { breakpoints as sizes } from 'styles/breakpoints'

interface ContainerProps {
  containerMaxWidth: string
}

interface SidebarProps {
  mobileOpen: boolean
}

interface HeroProps {
  background?: string
  video?: string
}

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  padding-top: 86px;
  padding-left: 280px;
  background: var(--gray-100);

  @media (${sizes.md}) {
    padding-left: 0;
  }
`

export const LayoutHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
`

export const LayoutSidebar = styled.div<SidebarProps>`
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 86px;
  height: 100%;
  background: #fff;
  z-index: 1;
  width: 280px;
  height: 100%;
  border-right: 1px solid var(--gray-300);

  @media (${sizes.md}) {
    display: none;

    ${(props) =>
      props.mobileOpen &&
      css`
        display: block;
      `}
  }
`

export const LayoutHero = styled.div<HeroProps>`
  position: relative;
  height: 300px;
  background: url(${(props) => props.background}) center center no-repeat;
  background-size: cover;
`

export const LayoutHeroVideo = styled.div<HeroProps>`
  position: relative;
  height: auto;
  background: var(--gray-900);
  padding-top: 20px;
  padding-bottom: 20px;

  .inner {
    max-width: 70%;
    margin: 0 auto;
  }
`

export const LayoutContainer = styled.div<ContainerProps>`
  width: 90%;
  max-width: ${(props) => props.containerMaxWidth}px;
  margin: 60px auto;
`
