import Button from 'components/Button'
import Input from 'components/Input'
import { useAuth } from 'context/AuthContext'
import { Form, Formik } from 'formik'
import { Link, useLocation } from 'react-router-dom'
import formValidation from './formValidation'

interface FormLoginProps {
  defaultColor?: string
}

const FormLogin = ({ defaultColor }: FormLoginProps) => {
  const { signIn, loading } = useAuth()
  const { pathname } = useLocation()

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={(values) => signIn(values)}
      validationSchema={formValidation}
    >
      {({ values, handleChange, handleBlur, errors, touched }) => (
        <Form>
          <Input
            type="email"
            name="email"
            value={values.email}
            placeholder="E-mail"
            style={{ marginBottom: '15px' }}
            onChange={handleChange}
            onBlur={handleBlur}
            validationError={
              errors.email && touched.email ? errors.email : null
            }
          />

          <Input
            type="password"
            name="password"
            value={values.password}
            placeholder="Senha"
            style={{ marginBottom: '15px' }}
            onChange={handleChange}
            onBlur={handleBlur}
            validationError={
              errors.password && touched.password ? errors.password : null
            }
          />

          <Button
            type="submit"
            loading={loading}
            defaultColor={defaultColor}
            block
          >
            Entrar
          </Button>

          <Link
            to={`${pathname}?type=recovery`}
            style={{
              display: 'block',
              textAlign: 'center',
              marginTop: '10px'
            }}
          >
            &raquo; Recuperar senha
          </Link>
        </Form>
      )}
    </Formik>
  )
}

export default FormLogin
