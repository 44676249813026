import { ReactNode } from 'react'
import * as S from './styles'

export interface SidebarButtonProps {
  title: string
  icon: JSX.Element
  iconColor?: string
  children: ReactNode
  tag?: JSX.Element
}

const SidebarButton = ({
  title,
  icon,
  iconColor,
  children,
  tag
}: SidebarButtonProps) => (
  <S.Wrapper iconColor={iconColor}>
    {icon}
    <S.Content>
      <span className="title">{title}</span>
      <p>{children}</p>
      {!!tag && <S.Tag>{tag}</S.Tag>}
    </S.Content>
  </S.Wrapper>
)

export default SidebarButton
