import {
  AspectRatio,
  Box,
  Center,
  Container,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Text
} from '@chakra-ui/react'
import { UserProps, WebsiteProps } from 'App.d'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from 'services/api'

import { SocialIcon } from 'react-social-icons'

import Hero from './components/Hero'
import _ from 'lodash'

const WebsitePreview = () => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const { slug } = useParams()
  const [user, setUser] = useState<UserProps | null>(null)
  const [website, setWebsite] = useState<WebsiteProps>({} as WebsiteProps)
  const [video, setVideo] = useState<string | null>(null)
  const [social, setSocial] = useState<string[] | null>(null)

  const containerMaxWidth = '800px'

  useEffect(() => {
    api.get(`/sites/slug/${slug}`).then((response) => {
      const { user } = response.data

      console.log(response.data)

      setWebsite(response.data)
      setVideo(response.data.videoPath)
      setUser(user)

      const socialList: string[] = _.values(
        _.pickBy(response.data, (value, key) => {
          return (
            [
              'facebook',
              'instagram',
              'youtube',
              'tiktok',
              'twitter',
              'linkedin'
            ].includes(key) && value !== null
          )
        })
      )

      setSocial(_.values(socialList))
    })
  }, [slug])

  return (
    <>
      <Hero backgroundImage={website.cardPath} />
      <Container
        maxWidth={containerMaxWidth}
        paddingTop={{ base: '20px', lg: '60px' }}
      >
        {video && (
          <AspectRatio ratio={16 / 9}>
            <video width="100%" height="100%" controls ref={videoRef}>
              <source src={video} />
            </video>
          </AspectRatio>
        )}
      </Container>
      <Container
        maxWidth={containerMaxWidth}
        paddingTop={{ base: '30px', md: '60px' }}
      >
        <Grid templateColumns="repeat(12, 1fr)" gap={6}>
          <GridItem
            colSpan={{ base: 12, md: 5 }}
            paddingX={{ base: '60px', md: '0px' }}
          >
            <AspectRatio ratio={1 / 1}>
              <Image
                src={website.photoPath}
                borderRadius="full"
                objectFit="cover"
                boxSize="100%"
                fallbackSrc="https://via.placeholder.com/800"
              />
            </AspectRatio>
          </GridItem>

          <GridItem colSpan={{ base: 12, md: 7 }} alignSelf="center">
            <div>
              <Heading size="lg" marginBottom={4}>
                Sobre
              </Heading>
              <Text marginBottom={4} whiteSpace="pre-line">
                {website.bio}
              </Text>
            </div>
          </GridItem>
        </Grid>
      </Container>

      {social && (
        <Container
          maxWidth={containerMaxWidth}
          paddingTop={{ base: '30px', lg: '60px' }}
        >
          <Center marginBottom={6}>
            <Heading size="lg" textAlign="center">
              Me acompanhe nas redes sociais
            </Heading>
          </Center>

          <Center>
            <HStack spacing={3}>
              {social.map((value) => (
                <SocialIcon
                  key={value}
                  url={value}
                  href={value}
                  target="_blank"
                />
              ))}
            </HStack>
          </Center>
        </Container>
      )}

      <Box
        backgroundColor="gray.100"
        paddingY={10}
        marginTop={{ base: '30px', lg: '60px' }}
      >
        <Container maxWidth={containerMaxWidth}>
          <Center flexDirection="column">
            <Image src={user?.campaign?.party?.logoPath} width="200px" />
            <Text marginTop={6}>CNPJ da campanha: {user?.campaign?.cnpj}</Text>
            <Text marginTop={1}>Todos os direitos reservados</Text>
          </Center>
        </Container>
      </Box>
    </>
  )
}

export default WebsitePreview
