import * as S from './styles'
import { BsCheck2All } from 'react-icons/bs'

export interface CardProps {
  imgUrl?: string
  title: string
  downloaded?: boolean | null
  description?: string
  footer?: JSX.Element | string
}

const Card = ({
  imgUrl,
  title,
  downloaded = null,
  description,
  footer
}: CardProps) => (
  <S.Wrapper withFooter={!!footer}>
    {!!imgUrl && (
      <S.CardImage>
        <S.CardImageInner>
          <img src={imgUrl} />
        </S.CardImageInner>
      </S.CardImage>
    )}
    <S.CardBody className="card-body">
      <S.CardTitle>{title}</S.CardTitle>
      <div>
        {downloaded === true && (
          <S.Downloaded>
            <BsCheck2All size={20} color="#2ecc71" /> Ítem já baixado
          </S.Downloaded>
        )}

        {downloaded === false && (
          <S.Downloaded>
            <BsCheck2All size={20} color="#bdc3c7" /> Nunca foi baixado
          </S.Downloaded>
        )}
      </div>
      {!!description && <S.CardDescription>{description}</S.CardDescription>}
    </S.CardBody>
    {!!footer && <S.CardFooter>{footer}</S.CardFooter>}
  </S.Wrapper>
)

export default Card
