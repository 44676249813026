import styled from 'styled-components'

export const Wrapper = styled.table`
  width: 100%;
  font-size: 0.9375rem;

  thead {
    border-bottom: 1px solid var(--gray-400);

    tr th {
      padding: 10px;
      font-size: 0.875rem;
    }
  }

  tbody {
    tr td {
      padding: 14px 10px;
      border-bottom: 1px solid var(--gray-200);
    }
  }
`
