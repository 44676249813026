import { Grid, GridItem, Tooltip, useToast } from '@chakra-ui/react'
import { GraphicsMaterialProps, UserProps } from 'App.d'
import Button from 'components/Button'
import Card from 'components/Card'
import Panel from 'components/Panel'
import { useCallback, useEffect, useState } from 'react'
import { FiEdit, FiImage, FiTrash } from 'react-icons/fi'
import { Link, useParams } from 'react-router-dom'
import api, { apiURL } from 'services/api'
import { loggedUserIsAdmin } from 'services/helpers'
import Layout from 'templates/Layout'

const UserGraphicsMaterial = () => {
  const { id } = useParams()
  const toast = useToast()
  const [user, setUser] = useState<UserProps>({} as UserProps)
  const [materials, setMaterials] = useState<GraphicsMaterialProps[]>([])

  const handleDelete = useCallback(
    async (materialId: string) => {
      try {
        if (confirm('Tem certeza que deseja deletar?')) {
          await api.delete(`/material-graphics/${materialId}`)

          toast({
            description: 'Material deletado com sucesso',
            status: 'success'
          })

          setMaterials(
            materials.filter((material) => material.id !== materialId)
          )
        }
      } catch (error) {
        toast({
          description: 'Ocorreu um erro ao deletar o material',
          status: 'error'
        })
      }
    },
    [toast, materials]
  )

  const setDownloaded = (materialId: string | undefined) => {
    const material = materials.filter((item) => item.id === materialId)[0]
    const materialDownaloded = { ...material, downloaded: true }
    const allMaterials = materials.map((item) => {
      return item.id === materialId ? materialDownaloded : item
    })
    setMaterials(allMaterials)
  }

  useEffect(() => {
    api.get(`/users/${id}`).then((response) => {
      setUser(response.data)
    })
  }, [id])

  useEffect(() => {
    api.get(`/material-graphics/?userId=${id}`).then((response) => {
      setMaterials(response.data)
      console.log(response.data)
    })
  }, [id])

  return (
    <Layout>
      {loggedUserIsAdmin() && (
        <Link to={`/graphics-material/upload/${id}`}>
          <Button
            size="small"
            style={{ marginBottom: '20px', marginLeft: 'auto' }}
          >
            Adicionar material gráfico
          </Button>
        </Link>
      )}

      <Panel
        title={
          loggedUserIsAdmin()
            ? `Materiais gráficos do usuário ${user.fullName}`
            : 'Materiais gráficos disponíveis para download'
        }
        icon={<FiImage color="#3498DB" />}
      >
        <Grid templateColumns="repeat(12, 1fr)" gap={6}>
          {materials.map((material) => (
            <GridItem
              key={material.userId}
              colSpan={{ base: 12, sm: 6, md: 6, lg: 3 }}
            >
              <Card
                imgUrl="/pdf.png"
                title={material.title}
                downloaded={material.downloaded}
                footer={
                  loggedUserIsAdmin() ? (
                    <div style={{ display: 'flex' }}>
                      <Tooltip label="Editar" hasArrow placement="top">
                        <Link
                          to={`/graphics-material/${material.id}`}
                          style={{ marginRight: '8px' }}
                        >
                          <FiEdit size={25} color="#3498DB" />
                        </Link>
                      </Tooltip>

                      <Tooltip label="Deletar" hasArrow placement="top">
                        <Link to="#">
                          <FiTrash
                            size={25}
                            color="#E74C3C"
                            onClick={() => handleDelete(material.id)}
                          />
                        </Link>
                      </Tooltip>
                    </div>
                  ) : (
                    <a
                      href={`${apiURL}/downloads/?type=material-graphics&id=${material.id}`}
                    >
                      <Button
                        size="small"
                        block
                        onClick={() => setDownloaded(material.id)}
                      >
                        Download
                      </Button>
                    </a>
                  )
                }
              />
            </GridItem>
          ))}
        </Grid>
      </Panel>
    </Layout>
  )
}

export default UserGraphicsMaterial
