import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import { useNavigate, useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import {
  UserProps,
  DigitalProps,
  OfficeProps,
  PartyProps,
  ThemeProps
} from 'App.d'
import api from 'services/api'
import { Form, Formik } from 'formik'
import Select from 'components/Select'
import UploadZone from 'components/UploadZone'
import Button from 'components/Button'
import { useToast } from '@chakra-ui/react'
import { FiInstagram } from 'react-icons/fi'

const AddDigital = () => {
  const { id } = useParams()
  const toast = useToast()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [users, setUsers] = useState<UserProps[]>([])
  const [offices, setOffices] = useState<OfficeProps[]>([])
  const [parties, setParties] = useState<PartyProps[]>([])
  const [themes, setThemes] = useState<ThemeProps[]>([])
  const [data] = useState<DigitalProps>({
    userId: id ? id : 'all',
    title: '',
    partyId: '',
    material: [],
    category: ''
  })

  const handleSubmit = useCallback(
    async (values: DigitalProps) => {
      try {
        const form = new FormData()

        if (values.userId && values.userId !== 'all')
          form.append('userId', values.userId)

        if (values.partyId) form.append('partyId', values.partyId)
        if (values.themeId) form.append('themeId', values.themeId)
        if (values.category) form.append('category', values.category)

        form.append('title', values.title)

        values.material.map((material) => {
          form.append('material', material)
        })

        const response = await api.post('/material-digitals', form)

        if (response.request.status === 200) {
          toast({
            description: 'Materiais cadastrados com sucesso',
            status: 'success'
          })
          setLoading(false)

          if (values.userId === 'all') {
            navigate(`/digital/shared?partyId=${values.partyId}`)
          } else {
            navigate(`/digital/user/${id}`)
          }
        }
      } catch (error) {
        console.log(error)
        setLoading(false)
      }
    },
    [navigate, toast, id]
  )

  useEffect(() => {
    api.get('/offices').then((response) => {
      setOffices(response.data)
    })
  }, [])

  useEffect(() => {
    api.get('/parties').then((response) => {
      setParties(response.data)
    })
  }, [])

  useEffect(() => {
    api.get('/themes').then((response) => {
      setThemes(response.data)
    })
  }, [])

  useEffect(() => {
    setFormLoading(true)

    api.get(`/users`).then((response) => {
      setUsers(response.data.filter((user: UserProps) => user.type === 'user'))
      setFormLoading(false)
    })
  }, [id])

  return (
    <Layout containerMaxWidth="600">
      <Panel
        title="Adicionar materiais digitais"
        icon={<FiInstagram color="#E74C3C" />}
      >
        {formLoading ? (
          'Carregando formulário'
        ) : (
          <Formik
            initialValues={data}
            onSubmit={(values) => {
              setLoading(true)
              handleSubmit(values)
            }}
            enableReinitialize
          >
            {({ values, handleBlur, handleChange, setFieldValue }) => (
              <Form>
                <Select
                  name="userId"
                  placeholder="Candidato(a)"
                  defaultValue={id ? id : values.userId}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                >
                  <option value="all">Todos</option>
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.fullName} ({user.campaign?.electoralName})
                    </option>
                  ))}
                </Select>

                {values.userId === 'all' && (
                  <>
                    <Select
                      name="partyId"
                      placeholder="Partido"
                      defaultValue={values.partyId}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{ marginBottom: '20px' }}
                    >
                      <option value="">Escolha</option>
                      {parties.map((party) => (
                        <option key={party.id} value={party.id}>
                          {party.name} ({party.initials})
                        </option>
                      ))}
                    </Select>

                    <Select
                      name="category"
                      placeholder="Categoria"
                      defaultValue={values.category}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{ marginBottom: '20px' }}
                    >
                      <option value="">Escolha</option>
                      {offices.map((office) => (
                        <option key={office.id} value={office.id}>
                          {office.title}
                        </option>
                      ))}
                    </Select>

                    <Select
                      name="themeId"
                      placeholder="Tema"
                      defaultValue={values.themeId}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      style={{ marginBottom: '20px' }}
                    >
                      <option value="">Escolha</option>
                      {themes.map((theme) => (
                        <option key={theme.id} value={theme.id}>
                          {theme.title}
                        </option>
                      ))}
                    </Select>
                  </>
                )}

                <Select
                  name="title"
                  placeholder="Tipo de material"
                  defaultValue={values.title}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  style={{ marginBottom: '20px' }}
                >
                  <option value="">Escolha</option>
                  <option value="Card para redes sociais">
                    Card para redes sociais
                  </option>
                  <option value="Card para Stories">Card para Stories</option>
                  <option value="Capa de Facebook">Capa de Facebook</option>
                  <option value="Avatar para redes sociais">
                    Avatar para redes sociais
                  </option>
                </Select>

                <UploadZone
                  multiple
                  onDrop={(files) => setFieldValue('material', files)}
                  style={{ marginBottom: '20px' }}
                />

                <Button type="submit" loading={loading}>
                  Enviar
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Panel>
    </Layout>
  )
}

export default AddDigital
