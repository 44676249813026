import { BsCheck2All } from 'react-icons/bs'
import * as S from './styles'

export interface CardProps {
  audioUrl?: string
  title: string
  description?: string
  downloaded?: boolean | null
  footer?: JSX.Element | string
}

const CardAudio = ({
  audioUrl,
  title,
  description,
  downloaded = null,
  footer
}: CardProps) => (
  <S.Wrapper withFooter={!!footer}>
    {!!audioUrl && (
      <div
        style={{
          background: '#333',
          padding: '10px',
          aspectRatio: '16 / 9',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <audio controls style={{ width: '100%' }}>
          <source src={audioUrl} />
        </audio>
      </div>
    )}
    <S.CardBody className="card-body">
      <S.CardTitle>{title}</S.CardTitle>
      <div>
        {downloaded === true && (
          <S.Downloaded>
            <BsCheck2All size={20} color="#2ecc71" /> Ítem já baixado
          </S.Downloaded>
        )}

        {downloaded === false && (
          <S.Downloaded>
            <BsCheck2All size={20} color="#bdc3c7" /> Nunca foi baixado
          </S.Downloaded>
        )}
      </div>
      {!!description && <S.CardDescription>{description}</S.CardDescription>}
    </S.CardBody>
    {!!footer && <S.CardFooter>{footer}</S.CardFooter>}
  </S.Wrapper>
)

export default CardAudio
