import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from 'context/AuthContext'
import {
  Avatar,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip
} from '@chakra-ui/react'
import * as S from './styles'
import { loggedUserIsAdmin } from 'services/helpers'
import { useEffect, useState } from 'react'
import api from 'services/api'

interface HeaderProps {
  mobileMenu?: JSX.Element
}

const Header = ({ mobileMenu }: HeaderProps) => {
  const { user, signOut } = useAuth()
  const navigate = useNavigate()
  const isAdmin = loggedUserIsAdmin()
  const [logo, setLogo] = useState<null | string>(null)
  const [loadLogo, setLoadLogo] = useState(true)

  useEffect(() => {
    if (user.type === 'user') {
      setLoadLogo(true)

      api.get(`/users/${user.id}`).then((response) => {
        const { campaign } = response.data
        setLogo(campaign.party.logoPath)
        setLoadLogo(false)
      })
    } else {
      setLogo('/logo-horizontal.svg')
      setLoadLogo(false)
    }
  }, [user])

  return (
    <S.Wrapper>
      {!!mobileMenu && <S.MobileMenuWrapper>{mobileMenu}</S.MobileMenuWrapper>}
      <S.LogoWrapper>
        <Link to="/">
          {!loadLogo && <S.Logo src={logo || ''} width="149" height="149" />}
        </Link>
      </S.LogoWrapper>
      <S.UserWrapper>
        <Menu>
          <Tooltip label="Ver opções" hasArrow placement="left">
            <MenuButton as={Avatar} size="sm" style={{ cursor: 'pointer' }} />
          </Tooltip>
          <MenuList>
            <MenuItem
              fontSize="sm"
              onClick={() => navigate(`/users/${user.id}`)}
            >
              Meus dados
            </MenuItem>

            {isAdmin && (
              <>
                <MenuItem fontSize="sm" onClick={() => navigate('/parties')}>
                  Partidos
                </MenuItem>
                <MenuItem fontSize="sm" onClick={() => navigate('/themes')}>
                  Temas
                </MenuItem>
                <MenuItem fontSize="sm" onClick={() => navigate('/flags')}>
                  Bandeiras
                </MenuItem>
              </>
            )}

            <MenuItem fontSize="sm" onClick={() => signOut()}>
              Sair
            </MenuItem>
          </MenuList>
        </Menu>
      </S.UserWrapper>
    </S.Wrapper>
  )
}

export default Header
