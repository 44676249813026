import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import * as S from './styles'
import { useAuth } from 'context/AuthContext'
import FormLogin from './FormLogin'
import FormRecovery from './FormRecovery'
import FormPassword from './FormPassword'
import api from 'services/api'

const Login = () => {
  const { user } = useAuth()
  const { slug } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [partyLogo, setPartyLogo] = useState<string | null>(null)
  const [partyColor, setPartyColor] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (user) {
      navigate('/', { replace: true })
    }
  }, [user, navigate])

  useEffect(() => {
    if (slug) {
      setLoading(true)

      api.get(`/parties/initials/${slug}`).then((response) => {
        const { logoPath, themeColor } = response.data
        setPartyLogo(logoPath)
        setPartyColor(themeColor)
        setLoading(false)
      })
    }
  }, [slug])

  return loading ? (
    <p>Carregando</p>
  ) : (
    <S.Wrapper color={partyColor ? partyColor : undefined}>
      <S.FormWrapper>
        <S.Logo src={partyLogo || '/logo-horizontal.svg'} />
        {!searchParams.get('type') && (
          <FormLogin defaultColor={partyColor || undefined} />
        )}
        {searchParams.get('type') === 'recovery' && <FormRecovery />}
        {searchParams.get('type') === 'password' && <FormPassword />}
      </S.FormWrapper>
    </S.Wrapper>
  )
}

export default Login
