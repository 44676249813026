import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import { Form, Formik } from 'formik'
import Input from 'components/Input'
import Button from 'components/Button'
import { useNavigate } from 'react-router-dom'
import { useCallback, useState } from 'react'
import { ThemeProps } from 'App.d'
import api from 'services/api'
import { useToast } from '@chakra-ui/react'

const AddTheme = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const [loading, setLoading] = useState(false)

  const handleSubmit = useCallback(
    async (values: ThemeProps) => {
      try {
        await api.post('/themes', values)

        toast({
          description: 'Tema cadastrado com sucesso',
          status: 'success'
        })

        setLoading(false)
        navigate('/themes')
      } catch (error) {
        console.log(error)
      }
    },
    [navigate, toast]
  )

  return (
    <Layout containerMaxWidth="600">
      <Panel title="Adicionar tema">
        <Formik
          initialValues={{ title: '' }}
          onSubmit={(values) => {
            setLoading(true)
            handleSubmit(values)
          }}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form>
              <Input
                name="title"
                value={values.title}
                placeholder="Nome do tema"
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ marginBottom: '20px' }}
              />

              <Button type="submit" loading={loading}>
                Enviar formulário
              </Button>
            </Form>
          )}
        </Formik>
      </Panel>
    </Layout>
  )
}

export default AddTheme
