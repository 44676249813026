import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import { Grid, GridItem, Input, Tooltip } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FiCamera } from 'react-icons/fi'
import Table from 'components/Table'
import { ChangeEvent, useEffect, useState } from 'react'
import { PhotoProps, UserProps } from 'App.d'
import api from 'services/api'

const Photos = () => {
  const [users, setUsers] = useState<UserProps[]>([])
  const [filteredUsers, setFilteredUsers] = useState<UserProps[]>([])
  const [photos, setPhotos] = useState<PhotoProps[]>([])

  const getPhotosCount = (userId: string) => {
    return photos.filter((photo) => photo.userId === userId).length
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase()

    setFilteredUsers(
      users.filter((user) => {
        if (value === '') return users

        return (
          user.fullName.toLowerCase().includes(value) ||
          user.campaign?.electoralName.toLowerCase().includes(value) ||
          user.campaign?.party?.initials.toLowerCase().includes(value)
        )
      })
    )
  }

  useEffect(() => {
    api.get('/photos').then((response) => {
      setPhotos(response.data)
    })
  }, [])

  useEffect(() => {
    api.get('/users').then((response) => {
      setUsers(response.data.filter((user: UserProps) => user.type === 'user'))
      setFilteredUsers(
        response.data.filter((user: UserProps) => user.type === 'user')
      )
    })
  }, [])

  return (
    <Layout>
      <Panel title="Fotos" icon={<FiCamera color="#f39c12" />}>
        <Grid
          templateColumns="repeat(3, 1fr)"
          gap={6}
          style={{ marginBottom: '20px' }}
        >
          <GridItem>
            <Input placeholder="Buscar" size="sm" onChange={handleSearch} />
          </GridItem>
        </Grid>

        <Table>
          <thead>
            <tr>
              <th>Usuário</th>
              <th>Nome de urna</th>
              <th>Partido</th>
              <th>Fotos</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.id}>
                <td>{user.fullName}</td>
                <td>{user.campaign?.electoralName}</td>
                <td>{user.campaign?.party?.initials}</td>
                <td>{getPhotosCount(user.id)} fotos</td>
                <td style={{ width: '70px' }}>
                  <div style={{ display: 'flex' }}>
                    <Tooltip hasArrow placement="top" label="Gerenciar fotos">
                      <Link to={`/photos/user/${user.id}`}>
                        <FiCamera size={20} color="#f39c12" />
                      </Link>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Panel>
    </Layout>
  )
}

export default Photos
