import axios, { AxiosRequestConfig } from 'axios'

export const apiURL = 'https://minhacandidatura.herokuapp.com'

const api = axios.create({
  baseURL: apiURL
})

api.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers!.Authorization = `Bearer ${localStorage.getItem(
    '@MinhaCandidatura:token'
  )}`

  console.log('request', config.method, config.url)

  return config
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      console.log('deslogar usuário')
      const user = localStorage.getItem('@MinhaCandidatura:user')

      localStorage.removeItem('@MinhaCandidatura:user')
      localStorage.removeItem('@MinhaCandidatura:token')

      if (user) {
        const userParsed = JSON.parse(user)

        if (userParsed.type === 'user') {
          const partyInitials = userParsed.campaign.party.initials.toLowerCase()
          window.location.replace(`/login/${partyInitials}`)
        } else {
          window.location.replace(`/login`)
        }
      }
    }
  }
)

export default api
