import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import api from 'services/api'
import { useAuth } from './AuthContext'
import { get } from 'lodash'

interface CampaignInfoContextProps {
  completedCampaignInfo: boolean
}

interface CampaignInfoProviderProps {
  children: React.ReactNode
}

const CampaignInfoContext = createContext<CampaignInfoContextProps>(
  {} as CampaignInfoContextProps
)

export const CampaignInfoProvider = ({
  children
}: CampaignInfoProviderProps) => {
  const { user } = useAuth()
  const [completedInfo, setCompletedInfo] = useState(false)

  const completedCampaignInfo = useCallback(async () => {
    if (!user) return false

    const response = await api.get(`/users/${user.id}`)
    const campaign = response.data.campaign

    if (campaign === null) {
      return false
    }

    const campaignValues = [
      get(campaign, 'electoralName'),
      get(campaign, 'electoralNumber'),
      get(campaign, 'city'),
      get(campaign, 'state'),
      get(campaign, 'officeId'),
      get(campaign, 'partyId'),
      get(campaign, 'cnpj')
    ]

    const campaignDataHasNull = campaignValues.some((item) => item === null)

    return !campaignDataHasNull
  }, [user])

  useEffect(() => {
    completedCampaignInfo().then((response) => {
      setCompletedInfo(response)
    })
  }, [completedCampaignInfo])

  return (
    <CampaignInfoContext.Provider
      value={{ completedCampaignInfo: completedInfo }}
    >
      {children}
    </CampaignInfoContext.Provider>
  )
}

export const useCampaignInfo = () => {
  const context = useContext(CampaignInfoContext)

  if (!context) {
    throw new Error(
      'useCampaignInfo must be used whitin an CampaignInfoProvider'
    )
  }

  return context
}
