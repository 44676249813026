import { useNavigate, useParams } from 'react-router-dom'
import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import Input from 'components/Input'
import Button from 'components/Button'
import { useCallback, useEffect, useState } from 'react'
import api from 'services/api'
import { FiUserCheck } from 'react-icons/fi'
import { Form, Formik } from 'formik'
import ibgeApi from 'services/ibge'
import { UserProps, PartyProps, OfficeProps, CampaignProps } from 'App.d'
import { Alert, AlertIcon, useToast } from '@chakra-ui/react'
import { loggedUserIsAdmin } from 'services/helpers'
import Select from 'components/Select'
import { useCampaignInfo } from 'context/CampaignInfoContext'
import formValidation from './formValidation'

interface StateProps {
  id: number
  nome: string
  sigla: string
}

const EditCampaign = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { id } = useParams()
  const { completedCampaignInfo } = useCampaignInfo()
  const isAdmin = loggedUserIsAdmin()
  const [formLoading, setFormLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [, setUser] = useState<UserProps>({} as UserProps)
  const [states, setStates] = useState<StateProps[]>([])
  const [offices, setOffices] = useState<OfficeProps[]>([])
  const [parties, setParties] = useState<PartyProps[]>([])

  const [campaign, setCampaign] = useState<CampaignProps>({
    partyId: '',
    officeId: '',
    electoralName: 'Teste',
    electoralNumber: '',
    cnpj: '',
    state: '',
    accessorName: '',
    accessorEmail: '',
    accessorPhone: ''
  })

  const handleSubmit = useCallback(
    async (values: CampaignProps) => {
      setLoading(true)

      try {
        const response = await api.put(`/users/${id}`, { campaign: values })

        if (response.status === 200) {
          toast({
            description: 'Os dados foram salvos com sucesso',
            status: 'success'
          })

          if (loggedUserIsAdmin()) {
            navigate('/users')
          } else {
            navigate(`/users/${id}/campaign`)
          }

          setLoading(false)
          window.location.reload()
        }
      } catch (error) {
        console.log(error)
      }
    },
    [id, navigate, toast]
  )

  useEffect(() => {
    setFormLoading(true)

    const getStates = ibgeApi.get('/estados')
    const getOffices = api.get('/offices')
    const getParties = api.get('/parties')
    const getUser = api.get(`/users/${id}`)

    Promise.all([getStates, getOffices, getParties, getUser]).then(
      (response) => {
        setStates(response[0].data)
        setOffices(response[1].data)
        setParties(response[2].data)
        setUser(response[3].data)
        setCampaign(response[3].data.campaign)

        setFormLoading(false)
      }
    )
  }, [id])

  return (
    <Layout containerMaxWidth="700">
      <Panel
        title={isAdmin ? 'Editar usuário' : 'Dados de campanha'}
        icon={<FiUserCheck color="1ABC9C" />}
      >
        {formLoading ? (
          'Carregando formulário'
        ) : (
          <>
            {!completedCampaignInfo && (
              <Alert
                status="warning"
                variant="left-accent"
                style={{
                  marginBottom: '30px',
                  fontSize: '0.9375rem',
                  lineHeight: '1.1875rem'
                }}
              >
                <AlertIcon />
                Por favor, preencha todos os seus dados de campanha para que
                possamos iniciar a produção de seus materiais.
              </Alert>
            )}

            {completedCampaignInfo && (
              <Alert
                status="success"
                variant="left-accent"
                style={{
                  marginBottom: '30px',
                  fontSize: '0.9375rem',
                  lineHeight: '1.1875rem'
                }}
              >
                <AlertIcon />
                Você preencheu seus dados de campanha com sucesso. Já estamos
                produzindo seus materiais e em breve avisaremos você.
              </Alert>
            )}

            <Formik
              initialValues={campaign}
              onSubmit={handleSubmit}
              validationSchema={formValidation}
              enableReinitialize
            >
              {({ values, handleBlur, handleChange, errors, touched }) => (
                <Form>
                  <Select
                    name="partyId"
                    defaultValue={values.partyId}
                    placeholder="Partido"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                    validationError={
                      errors.partyId && touched.partyId ? errors.partyId : null
                    }
                  >
                    <option value="">Escolha o partido</option>
                    {parties.map((party) => (
                      <option key={party.id} value={party.id}>
                        {`${party.name} (${party.initials})`}
                      </option>
                    ))}
                  </Select>

                  <Select
                    name="officeId"
                    defaultValue={values.officeId}
                    placeholder="Cargo em que irá concorrer"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                    validationError={
                      errors.officeId && touched.officeId
                        ? errors.officeId
                        : null
                    }
                  >
                    <option value="">Escolha o cargo</option>
                    {offices.map((office) => (
                      <option key={office.id} value={office.id}>
                        {office.title}
                      </option>
                    ))}
                  </Select>

                  <Input
                    name="electoralName"
                    placeholder="Nome de urna"
                    value={values.electoralName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                    validationError={
                      errors.electoralName && touched.electoralName
                        ? errors.electoralName
                        : null
                    }
                  />

                  <Input
                    name="electoralNumber"
                    placeholder="Número de candidato"
                    value={values.electoralNumber || ''}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                    validationError={
                      errors.electoralNumber && touched.electoralNumber
                        ? errors.electoralNumber
                        : null
                    }
                  />

                  <Input
                    name="cnpj"
                    mask="99.999.999/9999-99"
                    placeholder="CNPJ da campanha"
                    value={values.cnpj}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                    validationError={
                      errors.cnpj && touched.cnpj ? errors.cnpj : null
                    }
                  />

                  <Select
                    name="state"
                    defaultValue={values.state}
                    placeholder="Estado em que irá concorrer"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                    validationError={
                      errors.state && touched.state ? errors.state : null
                    }
                  >
                    <option value="">Escolha o estado</option>
                    {states.map((state) => (
                      <option key={state.id} value={state.sigla}>
                        {state.nome}
                      </option>
                    ))}
                  </Select>

                  <h5
                    style={{
                      marginBottom: '25px',
                      marginTop: '30px',
                      fontWeight: '600'
                    }}
                  >
                    Dados do assessor(a) (preenchimento opcional)
                  </h5>

                  <Input
                    name="acessorName"
                    placeholder="Nome do assessor(a)"
                    value={values.accessorName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                  />

                  <Input
                    name="accessorEmail"
                    placeholder="E-mail do assessor(a)"
                    value={values.accessorEmail}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                  />

                  <Input
                    name="accessorPhone"
                    placeholder="Telefone celular do assessor(a)"
                    value={values.accessorPhone}
                    mask="(99) 99999-9999"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    style={{ marginBottom: '20px' }}
                  />

                  <Button type="submit" loading={loading}>
                    Salvar informações
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Panel>
    </Layout>
  )
}

export default EditCampaign
