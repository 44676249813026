import styled, { css } from 'styled-components'

import { PanelProps } from '.'

type PanelHeaderProps = Pick<PanelProps, 'iconColor' | 'collapsible'>

type PanelContentProps = {
  closed: boolean
}

export const Wrapper = styled.div`
  padding: 30px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.09);
  border-radius: 1px;
`

export const PanelHeader = styled.header<PanelHeaderProps>`
  position: relative;
  display: flex;

  > svg {
    flex-shrink: 0;
    width: 26px;
    height: 26px;
    margin-right: 10px;
    stroke-width: 1.5px;
    color: ${(props) => props.iconColor};
  }

  .panel-arrow {
    position: absolute;
    top: 5px;
    right: 0;
  }
`

export const PanelTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: var(--gray-700);
`

export const PanelContent = styled.div<PanelContentProps>`
  padding-top: 30px;

  ${(props) =>
    props.closed &&
    css`
      display: none;
    `}
`
