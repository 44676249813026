import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import { FiImage } from 'react-icons/fi'
import { Grid, GridItem, Input, Tooltip } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import Table from 'components/Table'
import { GraphicsMaterialProps, UserProps } from 'App.d'
import { ChangeEvent, useEffect, useState } from 'react'
import api from 'services/api'

const GraphicsMaterial = () => {
  const [users, setUsers] = useState<UserProps[]>([])
  const [filteredUsers, setFilteredUsers] = useState<UserProps[]>([])
  const [materials, setMaterials] = useState<GraphicsMaterialProps[]>([])

  const getMaterialsCount = (userId: string) => {
    return materials.filter((material) => material.userId == userId).length
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase()

    setFilteredUsers(
      users.filter((user) => {
        if (value === '') return users

        return (
          user.fullName.toLowerCase().includes(value) ||
          user.campaign?.electoralName.toLowerCase().includes(value) ||
          user.campaign?.party?.initials.toLowerCase().includes(value)
        )
      })
    )
  }

  useEffect(() => {
    api.get('/material-graphics').then((response) => {
      setMaterials(response.data)
    })
  }, [])

  useEffect(() => {
    api.get('/users').then((response) => {
      setUsers(response.data.filter((user: UserProps) => user.type === 'user'))
      setFilteredUsers(
        response.data.filter((user: UserProps) => user.type === 'user')
      )
    })
  }, [])

  return (
    <Layout>
      <Panel title="Materiais Gráficos" icon={<FiImage color="#3498DB" />}>
        <Grid
          templateColumns="repeat(3, 1fr)"
          gap={6}
          style={{ marginBottom: '20px' }}
        >
          <GridItem>
            <Input placeholder="Buscar" size="sm" onChange={handleSearch} />
          </GridItem>
        </Grid>

        <Table>
          <thead>
            <tr>
              <th>Usuário</th>
              <th>Nome de urna</th>
              <th>Partido</th>
              <th>Materiais</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.id}>
                <td>{user.fullName}</td>
                <td>{user.campaign?.electoralName}</td>
                <td>{user.campaign?.party?.initials}</td>
                <td>{getMaterialsCount(user.id)} materiais</td>
                <td style={{ width: '70px' }}>
                  <div style={{ display: 'flex' }}>
                    <Tooltip
                      hasArrow
                      placement="top"
                      label="Gerenciar materiais"
                    >
                      <Link to={`/graphics-material/user/${user.id}`}>
                        <FiImage size={20} color="#3498DB" />
                      </Link>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Panel>
    </Layout>
  )
}

export default GraphicsMaterial
