import Layout from 'templates/Layout'
import Panel from 'components/Panel'
import { Form, Formik } from 'formik'
import InputFile from 'components/InputFile'
import Button from 'components/Button'
import { FlagProps } from 'App.d'
import { useCallback, useState } from 'react'
import Input from 'components/Input'
import api from 'services/api'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'

const AddFlag = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const [flag] = useState<FlagProps>({} as FlagProps)
  const [loading, setLoading] = useState(false)

  const handleSubmit = useCallback(
    async (values: FlagProps) => {
      try {
        const form = new FormData()

        if (values.image) form.append('image', values.image)
        if (values.description) form.append('description', values.description)
        form.append('title', values.title)

        await api.post('/flags', form)

        toast({
          description: 'Bandeira cadastrada com sucesso',
          status: 'success'
        })

        setLoading(false)
        navigate('/flags')
      } catch (error) {
        console.log(error)
      }
    },
    [navigate, toast]
  )

  return (
    <Layout containerMaxWidth="600">
      <Panel title="Adicionar bandeira">
        <Formik
          initialValues={flag}
          onSubmit={(values) => {
            setLoading(true)
            handleSubmit(values)
          }}
        >
          {({ values, handleBlur, handleChange, setFieldValue }) => (
            <Form>
              <InputFile
                label="Imagem"
                name="image"
                onBlur={handleBlur}
                onChange={(event) => {
                  if (event.target.files) {
                    const file = event.target.files[0]
                    setFieldValue('image', file)
                  }
                }}
                style={{ marginBottom: '20px' }}
              />

              <Input
                name="title"
                placeholder="Título"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ marginBottom: '20px' }}
              />

              <Input
                name="description"
                placeholder="Descrição"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ marginBottom: '20px' }}
              />

              <Button type="submit" loading={loading}>
                Enviar formulário
              </Button>
            </Form>
          )}
        </Formik>
      </Panel>
    </Layout>
  )
}

export default AddFlag
